import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "../../assets/styles/login.scss";
import ReturnIcon from "../../assets/images/return.svg";
import EyeOpened from "../../assets/images/open-eye.svg";
import Alert from "../../assets/images/danger.svg";
import EyeClosed from "../../assets/images/eyeClosed.svg";
import Check from "../../assets/images/check-1.svg";
import Header from '../../components/header';
import { useLocation, useNavigate } from 'react-router-dom';
import Services from '../../services/global.service';
import { toast } from 'react-toastify';
import { HelmetProvider ,Helmet} from 'react-helmet-async';

const NewPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  let email = location.state.email;
  let token = location.state.token;
  let code = location.state.code;
  const initialValues = {
    newPassword: '',
  };

  const handleToggle = () => {
    setShowPassword(!showPassword);
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
      .matches(/[a-z]/, 'Le mot de passe doit contenir au moins une lettre minuscule')
      .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une lettre majuscule')
      .matches(/\d|[!@#$%^&*(),.?":{}|<>]/, 'Le mot de passe doit contenir au moins un chiffre ou un caractère spécial')
      .required('Le mot de passe est obligatoire'),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    // console.log('Form data:', values);
    const data={
      token,
      email,
      password:values.newPassword,
      password_confirmation:values.newPassword,
      password_code:code
    }
    Services.create(data,'reset-password')
    .then((res)=>{
      toast.success('Votre opération a réussi !', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        onClose: () => navigate('/login'),
       // theme: 'light', // Choose theme (light/dark)
        className: 'custom-toast'

      });

    })
    .catch((err)=>{
      toast.error(err?.response?.data?.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
       // theme: 'light', // Choose theme (light/dark)
        className: 'custom-toast-error'

      });
    }
  )
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  return (
    <>
    <HelmetProvider>
      <Helmet>
        <title>Page Nouveau mot de passe </title>
        <meta name="description" content="page pour la creation d Nouveau mot de passe" />
      </Helmet>
    </HelmetProvider>
    
     <div className="desktop">
       <Header/> 
    </div>
    <div className="bg-atchoum">
        <div className="container-fluid-lg">
      <h1 className="text-white pt-8 px-16 d-flex">
         <button onClick={() => navigate(-1)} className='cta'><img src={ReturnIcon} className="mr-8" alt="return icon" /></button>
        Nouveau mot de passe
      </h1>
      <p className="exergue bleu3 px-16 pt-8">
        Créez votre nouveau mot de passe.
      </p>
    </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, isValid, dirty }) => {
          const isLongEnough = values.newPassword.length >= 8;
          const hasUppercase = /[A-Z]/.test(values.newPassword);
          const hasLowercase = /[a-z]/.test(values.newPassword);
          const hasNumberOrSpecial = /\d|[!@#$%^&*(),.?":{}|<>]/.test(values.newPassword);

          return (
            <Form className="mt-32">
              <div className="px-16 col-lg-4 mx-auto">
                <div className="floating-input-container position-relative">
                  <Field
                    type={showPassword ? 'text' : 'password'}
                    name="newPassword"
                    className={`floating-input ${touched.newPassword && errors.newPassword ? 'is-invalid' : ''}`}
                    id="newPassword"
                    placeholder=" "
                  />
                  <label htmlFor="newPassword" className="floating-label">
                    Nouveau mot de passe
                  </label>
                  <div
                    className="password-toggle-icon position-absolute"
                    style={{
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                    }}
                    onClick={handleToggle}
                  >
                    {showPassword ? (
                      <img src={EyeOpened} alt="Hide Password" />
                    ) : (
                      <img src={EyeClosed} alt="Show Password" />
                    )}
                  </div>
                </div>
                {/* <ErrorMessage name="email" component="div" className="invalid-feedback" /> */}
                <ErrorMessage name="email" >
                   {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
              </div>

            
              <div className="regles text-start p-16 mt-16 col-lg-4 mx-auto">
               {/* Password length rule */}
               <p className={`legend bleuM500 ${touched.newPassword && !isLongEnough ? 'text-danger' : 'text-muted'}`}>
                  <img src={Check} alt="icon check" className="mr-8" /> Plus de 8 caractères
                </p>
                
                {/* Uppercase and lowercase letter rule */}
                <p className={`legend bleuM500 ${touched.newPassword && !(hasUppercase && hasLowercase) ? 'text-danger' : 'text-muted'}`}>
                  <img src={Check} alt="icon check" className="mr-8" /> Au minimum une majuscule et une minuscule
                </p>

                {/* Number or special character rule */}
                <p className={`legend bleuM500 ${touched.newPassword && !hasNumberOrSpecial ? 'text-danger' : 'text-muted'}`}>
                  <img src={Check} alt="icon check" className="mr-8" /> Un chiffre ou un caractère spécial
                </p>
              </div>
              <div className="bottom-white-block">
                <button type="submit" disabled={!(isValid && dirty)} className="btn-primary-s p-16 w-sm-100">
                  Terminer
                </button>
              </div>

              
            </Form>
          );
        }}
      </Formik>
    </div>
    </>
  );
};

export default NewPassword;
