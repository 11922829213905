import Autocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Services from "../services/global.service";
import CalendarAtchoum from "./calendar";
import AutocompleteAdr from "./autocompleteAdr";
import { calculateAge } from "../utils/utils";
import { toast } from "react-toastify";

const Covoiturage = () => {
  const [depart, setDepart] = useState("");
  const [destination, setDestination] = useState("");
  const [departLatLng, setDepartLatLng] = useState(null);
  const [destinationLatLng, setDestinationLatLng] = useState(null);
  const [departDateTime, setDepartDateTime] = useState('');
  const [seats, setSeats] = useState('');
  const [modalCalendar, setModalCalendar] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState({ depart: {}, arrive: {} });
  const [forminput, setForminput] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isGMapsLoaded, setIsGMapsLoaded] = useState(false);
  const [commune, setCommune] = useState({ dept: "", dest: "" });
  const [age,setAge]=useState()
  const navigate = useNavigate();

  useEffect(() => {
    if (window.google) setIsGMapsLoaded(true)
    if (!window.google) {
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      gmapScriptEl.async = true;
      gmapScriptEl.defer = true;
      gmapScriptEl.onload = () => setIsGMapsLoaded(true);
      document.body.appendChild(gmapScriptEl);
    }
  }, []);
const createTarget=()=>{
  localStorage.removeItem('detailsTrajet')
  localStorage.removeItem('weekDays')
  const dataToSave = {
    confirmationDetails,
    depart,
    departLatLng,
    destination,
    destinationLatLng,
   // regulier,
    seats,
    commune,
   // postalCode,
   // transformedWeekDays, // Save only active days
  };
  localStorage.setItem("detailsTrajet", JSON.stringify(dataToSave));
  navigate("/details-covoiturage")
}
  const handleSelectOld = async (address, type) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      if (type === "destination") {
        if (
          address === depart || 
          (departLatLng && latLng.lat === departLatLng.lat && latLng.lng === departLatLng.lng)
        ) {
          alert("La destination doit être différente du départ.");
          return;
        }
        setDestination(address);
        setDestinationLatLng(latLng);
      } else {
        if (
          address === destination || 
          (destinationLatLng && latLng.lat === destinationLatLng.lat && latLng.lng === destinationLatLng.lng)
        ) {
          alert("Le départ doit être différent de la destination.");
          return;
        }
        setDepart(address);
        setDepartLatLng(latLng);
      }
      const addressComponents = results[0].address_components;
      // Find the component with 'locality' type for city/commune
      const cityComponent = addressComponents.find(component =>
        component.types.includes('locality')
      );
      const postalCode = addressComponents.find(component =>
        component.types.includes('postal_code')
      );
      const streetComponent = addressComponents.find(component =>
        component.types.includes('route')
      );
      // Set the commune (city)
      // console.log(cityComponent)
      if (cityComponent && postalCode ) {
        const cityName = cityComponent.long_name;
        setCommune((prevCommune) => {
          if (type === "destination") {
            return { ...prevCommune, dest: cityName }; // Set destination commune
          } else if (type === "depart") {
            return { ...prevCommune, dept: cityName }; // Set departure commune
          }
          return prevCommune;
        });
      }  else  {
       // console.error('Commune not found in the selected address');
        toast.error("Veuillez fournir l'adresse complète", {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSelect = async (address, type) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      // console.log(results)
     
        const addressComponents = results[0].address_components;

       // Find the component with 'locality' type for city/commune
       const cityComponent = addressComponents.find(component => 
        component.types.includes('locality')
      );
      const postalCode = addressComponents.find(component =>
        component.types.includes('postal_code')
      );
      // Set the commune (city)
      // console.log(cityComponent)
      if (cityComponent && postalCode) {
        if (type === "destination") {
          if (
            address === depart || 
            (departLatLng && latLng.lat === departLatLng.lat && latLng.lng === departLatLng.lng)
          ) {
            alert("La destination doit être différente du départ.");
            return;
          }
          setDestination(address);
          setDestinationLatLng(latLng);
        } else {
          if (
            address === destination || 
            (destinationLatLng && latLng.lat === destinationLatLng.lat && latLng.lng === destinationLatLng.lng)
          ) {
            alert("Le départ doit être différent de la destination.");
            return;
          }
          setDepart(address);
          setDepartLatLng(latLng);
        }
        const cityName = cityComponent.long_name;
        const code = postalCode.long_name;
      //   setPostalCode((prevCommune) => {
      //   if (type === "destination") {
      //     return { ...prevCommune, dest: code }; // Set destination commune
      //   } else if (type === "depart") {
      //     return { ...prevCommune, dept: code }; // Set departure commune
      //   }
      //   return prevCommune;
      // });
        setCommune((prevCommune) => {
          if (type === "destination") {
            return { ...prevCommune, dest: cityName }; // Set destination commune
          } else if (type === "depart") {
            return { ...prevCommune, dept: cityName }; // Set departure commune
          }
          return prevCommune;
        });
      } else  {
        console.error('Commune not found in the selected address');
        toast.error('Adresse sans code postal !!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleModalCalendar = (value) => {
    setForminput(value);
    setModalCalendar(!modalCalendar);
  };

  const formatDate = (dateString, setState) => {
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const date = new Date(year, month - 1, day);

    const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const monthNames = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

    const dayName = daysOfWeek[date.getDay()];
    const monthName = monthNames[month - 1];
    const [hours, minutes] = timePart.split(':');

    const formattedDate = `${dayName} ${day.toString().padStart(2, '0')} ${monthName} à ${hours}h${minutes}`;
    setState(formattedDate);
  };
useEffect(()=>{
  const idUser=localStorage.getItem('userData')
  // console.log('userData',idUser)
  Services.getOne(idUser,'users')
  .then((res)=>{
    // console.log(res)
   const age= calculateAge(res?.data?.data?.date_naissance)
    setAge(age)
  })
  .catch((err)=>{
    // console.log(err)
    
  })
},[])
  useEffect(() => {
    if (Object.keys(confirmationDetails?.depart).length !== 0) {
      confirmationDetails.depart.isFlexible
        ? setDepartDateTime('Je suis flexible')
        : formatDate(confirmationDetails?.depart?.date, setDepartDateTime);
    }
    //console.log('sets', !destination, !depart, !confirmationDetails?.depart?.date, !seats)
    setIsDisabled(!destinationLatLng || !departLatLng || Object.keys(confirmationDetails?.depart)?.length === 0 || !seats)
   
   
  }, [confirmationDetails, seats, destination, depart,departLatLng,destinationLatLng]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      adresseDepart: depart,
      adrDepartLongitude: departLatLng?.lng,
      adrDepartAltitude: departLatLng?.lat,
      adresseDestination: destination,
      adresseDesLongitude: destinationLatLng?.lng,
      adresseDesAltitude: destinationLatLng?.lat,
      dateDepart: confirmationDetails?.depart?.date,
      flexible: confirmationDetails?.depart?.isFlexible,
      isFlexibleDept: confirmationDetails?.depart?.isFlexible,
      selectedDayDept: confirmationDetails?.depart?.selectedDays,
      selectedPeriodsDept: confirmationDetails?.depart?.selectedPeriods,
      time:`${confirmationDetails?.depart?.time?.hour || '00'}:${confirmationDetails?.depart?.time?.minute || '00'}:00`,
      nbrPlace: parseInt(seats),
      communes: commune
    };
    const adrHistoric = [
      {
        address: depart,
        lng: departLatLng?.lng,
        lat: departLatLng?.lat,
      },
      {
        address: destination,
        lng: destinationLatLng?.lng,
        lat: destinationLatLng?.lat,
      },
    ];
    
    // Save to address history
    const addressHistoryKey = "addressHistory";
    const existingHistory = JSON.parse(localStorage.getItem(addressHistoryKey)) || [];
    
    // Flatten the array to avoid nesting and combine it with the existing history
    const updatedHistory = [...adrHistoric, ...existingHistory].slice(0, 5); // Keep only the last 5 entries
    
    // Save the updated history back to localStorage
    localStorage.setItem(addressHistoryKey, JSON.stringify(updatedHistory));
    

    // console.log(data)
    Services.create(data, "trajet/v2/recherche")
      .then((res) => {
        // console.log("Response", res.data);
        navigate('/search', { state: { searchData: res.data, inputs_data: data } });
      })
      .catch((err) => {
        // console.log("Error", err);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="mb-3 p-16">
        {modalCalendar && (
          <CalendarAtchoum
            open={modalCalendar}
            setOpen={setModalCalendar}
            home={true}
            type={forminput}
            setConfirmationDetails={setConfirmationDetails}
            confirmationDetails={confirmationDetails}
          />
        )}

        {/* Autocomplete for Depart */}
        <div className="form-floating">
          {isGMapsLoaded && 
                 <AutocompleteAdr depart={depart} setDepart={setDepart} handleSelect={handleSelect} type="depart" />

          }
        </div>

        {/* Autocomplete for Destination */}
        <div className="form-floating">
          {isGMapsLoaded &&
                    <AutocompleteAdr depart={destination} setDepart={setDestination} handleSelect={handleSelect} type="destination" />

          }
        </div>

        {/* Departure and Arrival DateTime */}
        <div className="form-floating mt-8">
          <input
            type="text"
            value={departDateTime}
            onClick={() => handleModalCalendar('depart')}
            className="date-home input-home  floating-input"
            placeholder="Date et heure de départ"
            readOnly
          />
        </div>



        {/* Number of Seats */}
        <div className="floating-input-container mt-8">
          <input
            type="number"
            value={seats}
            onChange={(e) => setSeats(e.target.value)}
            className="floating-input input-home"
            placeholder=""
            min={1}
          />
          <label htmlFor="seats" className="floating-label home-label">Nombre de places souhaitées</label>
        </div>



        <button type="submit" className="btn-primary-s mt-16 p-16 w-100" disabled={isDisabled}>Rechercher un covoiturage</button>
        <div className="mt-16">
          <button  className="btn-secondary-s  p-16 w-100 no-decoration" onClick={createTarget} disabled={age<18}>Proposer un covoiturage</button>
        </div>

      </form>
    </>
  );
};

export default Covoiturage;
