import { useNavigate } from "react-router-dom"; 
import ReturnIcon from "../../assets/images/return.svg";
import CheckFill from "../../assets/images/checkfill.svg";
import "../../assets/styles/bulletin.scss";
import Services from "../../services/global.service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { HelmetProvider ,Helmet} from "react-helmet-async";

const Assurance = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isOptionalAssurance, setIsOptionalAssurance] = useState(true); // Default to optional

  const getMyProfil = () => {
    const idUser = localStorage.getItem("userData");
    console.log("userData:", idUser);
    Services.getOne(idUser, "users")
      .then((res) => {
        const userData = res?.data?.data;
        if (userData) {
          setUser(userData);

          // Determine assurance status
          const { zonetus, adhesion_mobilite_village } = userData;
          if (zonetus) {
            setIsOptionalAssurance(true); // Assurance is optional in "tus" zone
          } else if (adhesion_mobilite_village && !zonetus) {
            setIsOptionalAssurance(false); // Assurance is mandatory
          } else {
            setIsOptionalAssurance(true); // Assurance is optional
          }
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    getMyProfil();
  }, []);

  const handlePayment = () => {
    const data = {
      price: parseFloat(5),
      text: "Assurance optionnelle Atchoum",
      urlSuccess: `${process.env.REACT_APP_BASE_URL_FRONT}confirmation-solidaire`,
      urlError: `${process.env.REACT_APP_BASE_URL_FRONT}assurance-solidaire`,
    };
    Services.create(data, "create-session")
      .then((res) => {
        // console.log(res);
        window.location.href = res.data.url;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
        // console.log(err);
      });
  };

  return (
    <>
    <HelmetProvider>
       <Helmet>
            <title>Page assurance </title>
            <meta name="description" content="page pour payer l assurance ou refuser" />
        </Helmet>
    </HelmetProvider>
        
      <div className="bg-grey">
        <div className="stepAssurance">
          <div className="container-fluid-lg p-16">
            <h1 className="text-white d-flex">
              <button onClick={() => navigate(-1)} className="cta">
                <img src={ReturnIcon} className="mr-8" alt="return icon" />
              </button>
              Devenir conducteur solidaire
            </h1>
          </div>
        </div>
        <div className="col-lg-6 mx-auto">
          <h2 className="p-16 m-0 bleuM500 container-fluid-lg">
            {isOptionalAssurance
              ? "Souscrivez à l'assurance optionnelle Atchoum"
              : "L'assurance Atchoum est obligatoire"}
          </h2>
          <p className="current-text p-16">
            Pourquoi bénéficier de l'assurance Atchoum ?
          </p>
          <div className="px-16">
            <div className="card-atchoum mt-24 mb-xs-100">
              <p className="current-text p-0 mb-0 bleuM500 align-items-start d-flex mt-8">
                <img src={CheckFill} alt="check icon" className="mr-8" />
                Lors de trajets effectués avec ATCHOUM, conducteurs et passagers
                sont couverts par La Macif
              </p>
              <p className="current-text p-0 mb-0 bleuM500 align-items-start d-flex mt-8">
                <img src={CheckFill} alt="check icon" className="mr-8" />
                Trajets assurés en Tous risques, quelque soit la couverture
                personnelle du conducteur
              </p>
              <p className="current-text p-0 mb-0 bleuM500 align-items-start d-flex mt-8">
                <img src={CheckFill} alt="check icon" className="mr-8" />
                Prix symbolique de 5€ par an
              </p>
              <div className="h-xs-100"></div>
              <div className="bottom-white-block">
                <div>
                  <button
                    type="submit"
                    onClick={handlePayment}
                    className="btn-primary-s p-16 w-sm-100"
                  >
                    Je souscris à l’assurance
                  </button>
                </div>
                {isOptionalAssurance && (
                  <button
                    onClick={() => {
                      navigate("/confirmation-solidaire");
                    }}
                    className="btn-secondary-s p-14 mt-8 w-sm-100"
                  >
                    Non merci
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Assurance;
