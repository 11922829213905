
import ConfirmationBloc from "../../components/confiramtionBloc"
import { HelmetProvider ,Helmet} from "react-helmet-async"

const ConfirmationDemand=()=>{
return(
   <>
   <HelmetProvider>
       <Helmet>
            <title>Page de confiramtion</title>
            <meta name="description" content="Page de confirmation" />
            
        </Helmet>
   </HelmetProvider>
  
   <ConfirmationBloc
    title={'Merci, votre demande de trajet a bien été prise en compte'}
    text={"Une notification vous sera envoyé par e-mail et SMS lorsque le conducteur le validera. Vous recevrez par retour ses coordonnées téléphoniques et lui les votre."}
    link={'/mes-trajets'}
    cta={'Voir mes trajets'}
    />
   </> 
)
}
export default ConfirmationDemand