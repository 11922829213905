import React, { useEffect, useState } from "react";
import ReturnIcon from "../../assets/images/return.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../assets/styles/demandeTrajet.scss";
import ArrowRight from "../../assets/images/Arrow-right.svg";
import Services from "../../services/global.service";
import { toast } from "react-toastify";
import { HelmetProvider ,Helmet} from "react-helmet-async";

function PaymentMethod() {
  const location = useLocation();
  const params = useParams();
  const [trajet, setTrajet] = useState(location?.state?.trajet || null);  
  const idConducteur = location?.state?.conducteur_id;
  // const allerRetour=trajet?.id ? 1 : (trajet?.searchData?.input_data?.isFlexibleDest || trajet?.searchData?.input_data?.dateArrive)? 2 : 1

  // console.log({idConducteur})
  const inputs = location?.state?.inputs_data;
  // console.log({inputs})
  const getNbrPlace = () => {
    if (!trajet?.id) {
      return 1; // Return 1 if there is no trajet.id
    }
    return trajet?.searchData?.input_data?.nbrPlace || inputs?.nbrPlace || 1;
  };
// console.log({trajet})
  useEffect(() => {
   

    if (trajet?.id) return;
    if(params?.id){
    Services.getOne(params?.id, "trajet/getOne")
        .then((res) => {
          // console.log(res);
          setTrajet(res?.data);
        })
        .catch((err) => {
          // console.log(err);
        });   
    }
    
  }, []);
  const handlePaymentCard = () => {
    const dataTrajet = {
      typeTrajet: trajet?.id ? 1 : 0, // Trajet solidaire
      adrDepartAltitude: trajet?.searchData?.input_data?.adrDepartAltitude || '',
      adrDepartLongitude: trajet?.searchData?.input_data?.adrDepartLongitude || '',
      adresseDepart: trajet?.searchData?.input_data?.adresseDepart || '',
      communes: trajet?.searchData?.input_data?.communes ? JSON.stringify(trajet.searchData.input_data.communes) : '',
      codePostalDepart:trajet?.searchData?.input_data?.postalCode?.dept || '',
      codePostalArrivee:trajet?.searchData?.input_data?.postalCode?.dest || '',
      adrFinalAltitude: trajet?.searchData?.input_data?.adresseDesAltitude || '',
      adresseFinal: trajet?.searchData?.input_data?.adresseDestination || '',
      adrFinalLongitude: trajet?.searchData?.input_data?.adresseDesLongitude || '',
      dateRetour: trajet?.searchData?.input_data?.dateArrive ,
      dateDepart: trajet?.searchData?.input_data?.dateDepart,
      nbrPlace: trajet?.searchData?.input_data?.nbrPlace || 1,
      conducteur:trajet?.id ? null : idConducteur ,
      prixTrajet: parseFloat(trajet?.prixTrajet) * parseInt(getNbrPlace()),
      isFlexibleDest: trajet?.searchData?.input_data?.isFlexibleDest || false,
      selectedDayDest: trajet?.searchData?.input_data?.selectedDayDest || '',
      selectedPeriodsDest: trajet?.searchData?.input_data?.selectedPeriodsDest || '',
      isFlexibleDep: trajet?.searchData?.input_data?.isFlexibleDep || false,
      selectedDayDept: trajet?.searchData?.input_data?.selectedDayDept || '',
      selectedPeriodsDept: trajet?.searchData?.input_data?.selectedPeriodsDept || '',
      commentaireTrajet: trajet?.searchData?.input_data?.commentaire || ''
    };

    //  console.log("Form data:", dataTrajet);
    
    const submitPayment = (trajetId) => {
    const data = {
      price: parseFloat(trajet?.prixTrajet)*parseInt(getNbrPlace()),
      nbrplace:trajet?.searchData?.input_data?.nbrPlace || inputs?.nbrPlace || 1,
      text: "Paiement trajet",
      trajet_id:trajetId,
      dateReservation: trajet?.searchData?.input_data?.dateDepart || inputs?.dateDepart || trajet?.dateDepart,
      urlSuccess: `${process.env.REACT_APP_BASE_URL_FRONT}confirmation-demande`,
      urlError: `${process.env.REACT_APP_BASE_URL_FRONT}paymentMethod/${params?.id}`,
    };
    // console.log({trajet,data})
    Services.create(data, "create-checkout-session")
      .then((res) => {
        // console.log(res);
        window.location.href = res.data.url;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
        // console.log(err);
      });
     }
     if (trajet?.id) {
      submitPayment(trajet.id);
    } else {
      // console.log('im in not existing trajet')
      // Otherwise, create the trajet first, then proceed to payment
      Services.create(dataTrajet, 'addTrajet')
        .then((res) => {
          // console.log("Trajet creation response:", res);
          submitPayment(res.data.trajet.id);
        })
        .catch((err) => {
          console.error("Error during trajet creation:", err);
        });
    }
  };
  const navigate = useNavigate();
  return (
    <>
    <HelmetProvider>
      <Helmet>
            <title>Page choix paiement </title>
            <meta name="description" content="page choix paiement ticket ou carte" />
        </Helmet>
    </HelmetProvider>
        
      <div className="bg-grey">
        <div className="stepOneDemande">
          <div className="container-fluid-lg p-16">
            <h1 className="text-white  d-flex align-items-center">
              <button onClick={() => navigate(-1)} className="cta">
                <img src={ReturnIcon} className="mr-8" alt="return icon" />
              </button>
              Demande de trajet
            </h1>
          </div>
        </div>
        <div className="p-16 col-lg-6 mx-auto text-start">
          <h2 className="bleuM500">Choisissez votre moyen de paiement</h2>
          <p className="current-text bleuM500 pb-16">
            C'est un prépaiement, vous ne serez débité que si un conducteur
            accepte votre demande de trajet.
          </p>
          <div onClick={handlePaymentCard}>
            <div className="card-atch p-16">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="exergue bleuM500 m-0 ">Carte bancaire</p>
                  <p className="current-text mb-0">{(parseFloat(trajet?.prixTrajet) *  parseInt(getNbrPlace()) ).toFixed(2)} €</p>
                </div>
                <img src={ArrowRight} alt="Arrow right" className="" />
              </div>
            </div>
          </div>
          {/* onClick={()=>{navigate(`/paymentMethod/${trajet.id}`,{state:{trajet}})}} */}
          <div onClick={()=>{navigate(`/tickets-mobilite`,{state:{trajet,inputs,conducteur_id:location?.state?.conducteur_id}})}}>
            <div className="card-atch p-16 mt-8">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="exergue bleuM500 m-0 ">Tickets mobilité</p>
                  <p className="current-text mb-0">{Math.ceil((parseFloat(trajet?.prixTrajet)* parseInt(getNbrPlace()))/1.25)} tickets mobilités</p>
                </div>
                <img src={ArrowRight} alt="Arrow right" className="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentMethod;
