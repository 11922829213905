import { ErrorMessage, Formik } from "formik";
import ReturnIcon from "../../assets/images/return.svg";
import Alert from "../../assets/images/danger.svg";
import Info from "../../assets/images/Information.svg";
import Header from "../../components/header";
import { Form, FormCheck } from "react-bootstrap";
import { useEffect, useState } from "react";

import Services from "../../services/global.service";
import { toast } from "react-toastify";
import InfoModal from "../../components/infoModal";
import { useNavigate } from "react-router-dom";
import { HelmetProvider ,Helmet} from "react-helmet-async";
import * as Yup from "yup";

const Car = () => {
  const navigate=useNavigate()
  const [initialValues, setInitialValues] = useState({
    matricule: "",
    marque: "",
    modele: "",
    couleur: "",
    pmr: false,
    animaux: false,
  });
  const validationSchema = Yup.object({
    matricule: Yup.string()
      .matches(/^[A-Z]{2}-\d{3}-[A-Z]{2}$/, "Le matricule doit être au format XX-XXX-XX.")
      .required("Le matricule est obligatoire."),
  });
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedpmr, setIsCheckedPmr] = useState(false);
  const textModal="Une voiture TPMR, ou Transport de Personnes à Mobilité Réduite, est un véhicule spécialement conçu pour répondre aux besoins de transport des personnes en situation de handicap ou à mobilité réduite. Cela inclut des rampes d’accès, des plateformes élévatrices, ect."
  const handleSwitchChange = (type) => {
    if (type === "pmr") {
      setIsCheckedPmr(!isCheckedpmr);
    } else {
      // console.log('hereeeee')
      setIsChecked(!isChecked);
    }
    // console.log(isChecked)
  };

  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    // console.log("Form data:", values);
  
    // Check if values are empty
    const isFormEmpty = 
      !values.matricule?.trim() && 
      !values.marque?.trim() && 
      !values.modele?.trim() && 
      !values.couleur?.trim();
  
    if (isFormEmpty) {
      // Redirect to the next step if the form is empty
      navigate('/perimetre-solidaire');
    } else {
      // Prepare data for submission
      const data = {
        immatriculation_vehicule: values.matricule,
        marque_vehicule: values.marque,
        modele_vehicule: values.modele,
        couleur_vehicule: values.couleur,
        accepte_pmr: isCheckedpmr,
        accepte_animaux: isChecked,
      };
  
      // Submit the form
      Services.create(data, 'update_user')
        .then((res) => {
          // console.log(res);
          navigate('/perimetre-solidaire');
        })
        .catch((err) => {
          // console.log(err);
          toast.error(err?.response?.data?.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
           // theme: 'light', // Choose theme (light/dark)
            className: 'custom-toast-error'
    
          });
        })
        .finally(() => {
          setSubmitting(false); // Set submitting to false when done
        });
    }
  };
  
  const getMyProfil = async () => {
    const idUser = localStorage.getItem('userData');
    try {
      const res = await Services.getOne(idUser, 'users');
      const car = res?.data?.data?.vehicule;
      // console.log(car)
      if (car) {
    
        setInitialValues({
          matricule: car?.immatriculation ,
          marque: car?.marque ,
          modele: car?.modele ,
          couleur: car?.couleur,
          pmr: car?.accepte_pmr ,
          animaux: car?.accepte_animaux ,
        });
        setIsChecked(car?.accepte_animaux );
        setIsCheckedPmr(car?.accepte_pmr );
        

      }
      
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getMyProfil();
  }, []);


  return (
    <>
    <HelmetProvider>
       <Helmet>
            <title>Page détails véhicule </title>
            <meta name="description" content="page pour renseigner les détails de ma véhicule " />
        </Helmet>
    </HelmetProvider>
    
      {open && <InfoModal open={open} setOpen={setOpen}
                 title={"Véhicule TPPMR"} text={textModal} />}
      {/* <div className="desktop">
        <Header connected={true} />
      </div> */}

      <div className="step4">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex align-items-center mb-100 ">
            <a href="javascript:history.go(-1)">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </a>
            Devenir conducteur solidaire
          </h1>
        </div>
        </div>
        <div className="mt-16 container-fluid-lg col-lg-4 mx-auto">
          <div className="px-16">
            <h2 className="bleuM500 mb-0">Votre véhicule</h2>
            <p className="current-text mt-8 bleuM500 text-start ">
              Vous pouvez ajouter des informations sur votre véhicule pour aider
              le passager à vous reconnaître plus facilement.
            </p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize // Allow reinitialization of form
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleSubmit, handleChange, values }) => (
              <Form onSubmit={handleSubmit} className="mt-24 mb-xs-100">
                <div className="px-16">
                  <div className="">
                    {/* matricule Field */}
                    <div className="floating-input-container">
                      <input
                        type="text"
                        name="matricule"
                        className={`floating-input ${
                          touched.matricule && errors.matricule
                            ? "is-invalid"
                            : ""
                        }`}
                        id="matricule"
                        placeholder=" "
                        value={values.matricule}
                        onChange={handleChange}
                      />
                      <label htmlFor="matricule" className="floating-label">
                        Plaque d’immatriculation
                      </label>
                    </div>
                    <ErrorMessage name="matricule">
                      {(msg) => (
                        <div className="error-message">
                          <img src={Alert} alt="Alert" />
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                    {/* <ErrorMessage name="matricule" component="div" className="invalid-feedback" /> */}
                  </div>

                  <div className="col-lg-12 mx-auto mt-24">
                    {/* marque Field */}
                    <div className="floating-input-container">
                      <input
                        type="text"
                        name="marque"
                        className={`floating-input ${
                          touched.marque && errors.marque ? "is-invalid" : ""
                        }`}
                        id="marque"
                        placeholder=" "
                        value={values.marque}
                        onChange={handleChange}
                      />
                      <label htmlFor="marque" className="floating-label">
                        Marque du véhicule
                      </label>
                    </div>
                    <ErrorMessage name="marque">
                      {(msg) => (
                        <div className="error-message">
                          <img src={Alert} alt="Alert" />
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                    {/* <ErrorMessage name="marque" component="div" className="invalid-feedback" /> */}
                  </div>

                  <div className="col-lg-12 mx-auto mt-24">
                    {/* modèle Field */}
                    <div className="floating-input-container">
                      <input
                        type="text"
                        name="modele"
                        className={`floating-input ${
                          touched.modele && errors.modele ? "is-invalid" : ""
                        }`}
                        id="modele"
                        placeholder=" "
                        value={values.modele}
                        onChange={handleChange}
                      />
                      <label htmlFor="modele" className="floating-label">
                        Modèle du véhicule
                      </label>
                    </div>
                    <ErrorMessage name="modele">
                      {(msg) => (
                        <div className="error-message">
                          <img src={Alert} alt="Alert" />
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                    {/* <ErrorMessage name="modele" component="div" className="invalid-feedback" /> */}
                  </div>

                  <div className="col-lg-12 mx-auto mt-24">
                    {/* couleur Field */}
                    <div className="floating-input-container">
                      <input
                        type="text"
                        name="couleur"
                        className={`floating-input ${
                          touched.couleur && errors.couleur ? "is-invalid" : ""
                        }`}
                        id="couleur"
                        placeholder=" "
                        value={values.couleur}
                        onChange={handleChange}
                      />
                      <label htmlFor="couleur" className="floating-label">
                        Couleur du véhicule
                      </label>
                    </div>
                    <ErrorMessage name="couleur">
                      {(msg) => (
                        <div className="error-message">
                          <img src={Alert} alt="Alert" />
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                    {/* <ErrorMessage name="couleur" component="div" className="invalid-feedback" /> */}
                  </div>
                </div>

                <div className="p-16">
                  <div className="col">
                    <div className="custom-switch custom-switch-label-io d-flex align-items-center">
                      <FormCheck
                        type="switch"
                        id="custom-switch-pmr"
                        className="form-switch mr-8"
                        checked={isCheckedpmr}
                        onChange={() => handleSwitchChange("pmr")}
                      />
                      <label
                        className="bleu600 legend"
                        htmlFor="custom-switch-pmr"
                      >
                        Mon véhicule est équipé pour accueillir une personne à
                        mobilité réduite (PMR)
                      </label>
                      <button
                      type="button" // Prevents the form from submitting
                      className="cta"
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      <img src={Info} alt="info icon " />
                    </button>
                    </div>
                  </div>
                </div>

                <div className="px-16">
                  <div className="col">
                    <div className="custom-switch custom-switch-label-io d-flex align-items-center">
                      <FormCheck
                        type="switch"
                        id="custom-switch"
                        className="form-switch mr-8"
                        checked={isChecked}
                        onChange={() => handleSwitchChange("animaux")}
                      />
                      <label className="bleu600 legend" htmlFor="custom-switch">
                        J’accepte les animaux de compagnie à bord
                      </label>
                    </div>
                  </div>
                </div>

                <div className="bottom-white-block">
                  <button type="submit" className="btn-primary-s p-16 w-sm-100">
                    Suivant
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
     
    </>
  );
};

export default Car;
