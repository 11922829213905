import React, { useEffect, useState, useRef } from "react";
import ReturnIcon from "../../assets/images/return.svg";
import { useNavigate, useParams } from "react-router-dom";
import voirPlus from "../../assets/images/voirPlus.svg";
import AvatarGrp from "../../assets/images/AvatarGrp.svg";
import "../../assets/styles/messagerie.scss";
import DetailMessage from "./detailMessage";
import NewMessage from "./newMessage";
import Services from "../../services/global.service";
import PopUpConfirmation from "./popUpConfirmation";
import io from 'socket.io-client';

import { HelmetProvider ,Helmet} from "react-helmet-async";

function Discussion() {
  const navigate = useNavigate();
  const params = useParams();
  const [friend, setFriend] = useState();
  const [disc, setDisc] = useState();
  const [messages, setMessages] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false); // Suivi de l'état de la connexion

  const messagesEndRef = useRef(null); // Ref for the messages container

  const SOCKET_SERVER_URL = 'https://bo.atchoum.lebackyard.ovh:3250';
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const optionsDay = { weekday: "short", day: "numeric", month: "short" };
    const optionsTime = { hour: "2-digit", minute: "2-digit" };

    if (date.toDateString() === now.toDateString()) {
      return `${date.toLocaleTimeString("fr-FR", optionsTime)}`;
    } else {
      return `${date.toLocaleDateString("fr-FR", optionsDay)} - ${date.toLocaleTimeString("fr-FR", optionsTime)}`;
    }
  };

  useEffect(() => {
    // Charger la discussion au premier rendu
    getDiscussion();
  }, []); // Ce useEffect ne se déclenche qu'une seule fois, lors du premier rendu du composant

  const getDiscussion = () => {
    Services.getOne(params.idDiscussion, "messages/getDiscussions")
      .then((res) => {
        setDisc(res?.data);
        if (res?.data?.typeGroupe === "prive") {
          setFriend(res?.data?.members[0]?.group_user);
        }
        const formattedData = res?.data?.discussion_group?.discussion_message.map((item) => ({
          content: item.message,
          sender: item?.sender,
          id: item?.id,
          deleted: item?.deleted_msg_sender,
          isMine: item?.sender_id == localStorage.getItem("userData"),
          timestamp: item?.created_at,
        }));
        setMessages(formattedData);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    const socketIo = io(SOCKET_SERVER_URL, {
      transports: ['websocket'],
    });

    setSocket(socketIo);

    // Lors de la connexion
    socketIo.on('connect', () => {
       console.log('Connected to WebSocket server');
      setIsConnected(true);

      // Rejoindre la discussion via le socket
      socketIo.emit('join_discussion', params.idDiscussion); // On passe l'ID de la discussion
    });

    // Quand la connexion est fermée
    socketIo.on('disconnect', () => {
       console.log('Disconnected from WebSocket server');
      setIsConnected(false);
    });

    // Réception des messages du serveur
    socketIo.on('discussion_message', (msg) => {
      console.log('Message reçu:', msg);  // Affichez le message reçu dans la console
      getDiscussion()
    });

    // Gérer les erreurs de connexion
    socketIo.on('connect_error', (err) => {
      console.log(`Erreur de connexion : ${err}`);
    });

    // Nettoyage de la connexion WebSocket lorsque le composant se démonte
    return () => {
      socketIo.disconnect();
    };
  }, [params.idDiscussion]); // Ajoutez params.idDiscussion pour gérer le changement de discussion

  // Scroll vers le bas quand un nouveau message est ajouté
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom(); // Appel après que les messages ont changé
  }, [messages]);

  // Fonction pour envoyer un message
  const onSend = (message) => {
    const newMessage = {
      content: message,
      isMine: true,
      timestamp: new Date(),
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    const data = {
      discussion_id: params.idDiscussion,  // Utilisation de l'ID de la discussion
      message,
    };

    Services.create(data, "messages/send")
      .then((res) => {
        // console.log("Message envoyé:", res);
        getDiscussion(); // Recharger la discussion et les messages

        // Emission du message via WebSocket
        if (socket) {
          socket.emit('send_message_to_discussion', {
            discussion_id: params.idDiscussion, // ID de la discussion
            message
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };


  return (
    <div className="container-fluid-lg p-16 bg-grey">
      <HelmetProvider>
        <Helmet>
            <title>Page discussion </title>
            <meta name="description" content="page discussion avec ami" />
        </Helmet>
      </HelmetProvider>
       
      <div className="col-lg-6 mx-auto">
        <PopUpConfirmation show={showPopUp} setShow={setShowPopUp} idDisc={disc?.discussion_group?.id} idUser={friend?.id} />
        <div className="d-flex justify-content-between align-items-start">
          <button onClick={() => navigate(-1)} className="cta">
            <img src={ReturnIcon} className="mr-8" alt="return icon" />
          </button>
          <div className="text-center d-flex flex-column align-items-center">
            <div className="w75 text-center">
              {disc?.typeGroupe === "prive" ? (
                <img src={friend?.photo_profil} alt="photoContact" className="driver-icon" />
              ) : (
                <img src={AvatarGrp} alt="photoContact" className="driver-icon w75" />
              )}
            </div>
            <p className="subtitle text-centerbleuM500 mt-8">
              {disc?.typeGroupe === "prive" ? `${friend?.prenom} ${friend?.nom}` : disc?.groupe_name}
            </p>
            <div className="text-center mt-8">
              <a href={disc?.typeGroupe === "prive" ? `/amis/${friend?.id}` : `/group/${disc?.id}`} className="cta">
                Voir le profil
              </a>
            </div>
          </div>
          <button className="cta" onClick={() => { setShowPopUp(!showPopUp) }}>
            <img src={voirPlus} alt="voirPlus" />
          </button>
        </div>

        <div className="mt-19">
        {messages?.map((msg, index) => {
  const showTimestamp = index === 0 || // Always show the timestamp for the first message
    new Date(messages[index - 1]?.timestamp).getMinutes() !== new Date(msg.timestamp).getMinutes() || 
    new Date(messages[index - 1]?.timestamp).getHours() !== new Date(msg.timestamp).getHours() || 
    new Date(messages[index - 1]?.timestamp).getDate() !== new Date(msg.timestamp).getDate();

  return (
    <React.Fragment key={index}>
      {showTimestamp && (
        <p className="mt-16 color-time">{formatDate(msg?.timestamp)}</p>
      )}
      <DetailMessage
        setMessages={setMessages}
        isMine={msg?.isMine}
        id={msg?.id}
        text={
          msg?.deleted && msg?.sender?.id == localStorage.getItem("userData")
            ? "Message supprimé."
            : msg?.content
        }
        friendAvatar={msg?.sender?.photo_profil}
      />
    </React.Fragment>
  );
})}

          <div ref={messagesEndRef}></div>
        </div>

        <NewMessage placeholderText="Envoyez un message" onSend={onSend} />
      </div>
    </div>
  );
}

export default Discussion;