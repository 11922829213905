// src/components/Friend.jsx
import React, { useState } from "react";
import Services from "../../services/global.service";
import { useNavigate } from 'react-router-dom';
import SpinLoader from "../../components/SpinLoader";

function Friend({ reloadData,avatar, name, id, group = false, is_in_group = false, gid = null }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const spinnerStyle = {
    position: "absolute",
    right: "30px",
  };

  const checkdiscussion = (id) => {
    if (!group) {
      // console.log(`Checking discussion for id: ${id}`);
      setLoading(true);
      Services.getOne(id, 'messages/getOrCreateMyDiscussions')
        .then((res) => {
          // console.log(res?.data?.id);
          setLoading(false);
          navigate('/discussion/' + res?.data?.id);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
        });
    }
  };

  const sendInvit = (id) => {
    let data = {
      "groupID": gid,
      "userID": id
    }
    Services.create(data, 'groupes/invite')
      .then((res) => {
        // console.log(res?.data?.id);
        setLoading(false);
        reloadData()
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  }



  return (
    <div onClick={() => checkdiscussion(id)} >
      <div className="d-flex containerFriend p-8 mt-8 align-items-center justify-content-between">
      
        <div className=" d-flex justify-content-start align-items-center title-msg font-Livvic font-16"> 
           <img src={avatar} alt="driver" className="driver-icon mr-8" />
         <p className="exergue bleuM500">{name}
          </p>  
           </div>
        {loading && <SpinLoader color="#439f8c" size={20} style={spinnerStyle} />} {/* Use Spinner here */}
        {group && !is_in_group ? (
          <button className="btn-secondary-s " onClick={() => sendInvit(id)}>
            Inviter
          </button>
        ) : group ?
        <span className="sent">Invitation envoyée</span>
        :""}
      </div>
    </div>
  );
}

export default Friend;
