import { useNavigate, useParams } from "react-router-dom";
import ReturnIcon from "../../assets/images/return.svg";
import Message from "../../assets/images/MessageBgT.svg";
import Check from "../../assets/images/Checkvert.svg";
import { useEffect, useState } from "react";
import Services from "../../services/global.service";
import "../../assets/styles/amis.scss";
import Groups from "./listGroups";
import Tabs from "../../components/tabs";
import RaisonSignal from "../../components/modalRaison";
import TrajetGroup from "../../components/trajetGroup";
import { HelmetProvider ,Helmet} from "react-helmet-async";
const PublicProfil = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [raison, setRaison] = useState("");
  const [friend, setFriend] = useState();
  const getProfilFriend = () => {
    Services.getOne(params.id, "getOneUser")
      .then((res) => {
        // console.log(res);
        setFriend(res?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const updateInvitStatus = (idInvitation, status) => {
    const data = {
      etat: status,
      idInvitation,
    };
    Services.create(data, "amis/change-status-invitation")
      .then((res) => {
        // console.log(res?.data);
        if (status === "accepte") {
          getProfilFriend();
        } else {
          navigate("/amis-groupes");
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  useEffect(() => {
    getProfilFriend();
  }, []);
  const Profil = () => {
    return (
      <div className="p-16">
        <RaisonSignal
          open={open}
          setOpen={setOpen}
          raison={raison}
          setRaison={setRaison}
          user={friend?.ami.id}
        />
        {friend?.ami?.description && (
          <p className="current-text pt-16 pb-16 border-b">
            {friend?.ami?.description}
          </p>
        )}
        <p className="current-text bleuM500 mt-16">
          {friend?.ami?.nombreTrajets || 0} trajets effectués
        </p>
        <p className="current-text bleuM500 mt-16 border-b pb-16">
          {(() => {
            const createdAt = new Date(friend?.ami?.created_at);
            const memberSince = new Date(createdAt); // Replace with actual creation date
            const now = new Date();
            // Calculate the difference in years and months
            const yearsSinceCreation =
              now.getFullYear() - memberSince.getFullYear();
            let monthsSinceCreation =
              now.getMonth() - memberSince.getMonth() + yearsSinceCreation * 12;

            // Adjust if the day of the month in `now` is earlier than `memberSince`
            if (now.getDate() < memberSince.getDate()) {
              monthsSinceCreation -= 1;
            }

            // Adjust `yearsSinceCreation` if monthsSinceCreation is less than 12
            monthsSinceCreation = monthsSinceCreation % 12;
            if (yearsSinceCreation < 1) {
              if (monthsSinceCreation < 1) {
                return "Nouveau membre";
              } else if (monthsSinceCreation === 1) {
                return "Membre depuis 1 mois";
              } else {
                return `Membre depuis ${monthsSinceCreation} mois`;
              }
            } else if (yearsSinceCreation === 1) {
              return "Membre depuis 1 an";
            } else {
              return `Membre depuis ${yearsSinceCreation} ans`;
            }
          })()}
        </p>
        <div className="mt-16 text-start">
          <button
            className="cta text-start text-underline"
            onClick={() => {
              setOpen(!open);
            }}
          >
            Signaler ce membre
          </button>
        </div>
      </div>
    );
  };
  const OurTarget = () => {
    // Group trajets by etat if friend and nos_trajet are defined
    const groupedTrajets = friend?.nos_trajet?.reduce((acc, item) => {
      const { trajet } = item;
      if (!acc[trajet?.status]) {
        acc[trajet?.status] = [];
      }
      acc[trajet?.status].push(item);
      return acc;
    }, {});

    return (
      <div className="p-16">
        {groupedTrajets && Object.keys(groupedTrajets)?.length > 0 ? (
          Object.keys(groupedTrajets).map((etatKey) => (
            <div key={etatKey}>
              {/* Display the group title */}
              <h2 className="bleuM500 mb-0 pb-16 mt-8">
                {etatKey == 1
                  ? "Trajets en attente"
                  : etatKey == 2
                  ? "Trajets à venir"
                  : "Trajets passés"}
              </h2>
              {/* Render trajets within this group */}
              {groupedTrajets[etatKey]?.map((el, index) => (
                <TrajetGroup
                  key={index} // Use a unique key for each item
                  trajet={el?.trajet}
                  from="ami"
                  trajetType="aller"
                />
              ))}
            </div>
          ))
        ) : (
          <p className="current-text bleuM500">Aucun trajet disponible.</p>
        )}
      </div>
    );
  };
  const checkdiscussion = (id) => {
    // console.log(`Checking discussion for id: ${id}`);
    //setLoading(true);
    Services.getOne(id, "messages/getOrCreateMyDiscussions")
      .then((res) => {
        // console.log(res?.data?.id);
        // setLoading(false);
        navigate("/discussion/" + res?.data?.id);
      })
      .catch((err) => {
        // console.log(err);
        //  setLoading(false);
      });
  };
  const tabs = [
    { key: "profil", label: "Son profil", content: <Profil /> },
    { key: "trajets", label: "Nos trajets", content: <OurTarget /> },
  ];
  return (
    <div className="container-fluid-lg   bg-grey">
      <HelmetProvider>
        <Helmet>
          <title>Page profil utilisateur </title>
          <meta name="description" content="page profil utilisateur" />
        </Helmet>
      </HelmetProvider>
     
      <div className="col-lg-6 mx-auto ">
        <div className="d-flex justify-content-between p-16 align-items-start">
          <button onClick={() => navigate(-1)} className="cta">
            <img src={ReturnIcon} className="mr-8" alt="return icon" />
          </button>
        </div>
        <div className="px-16">
          <div className="card-atchoum mr-0   bg-white public-profil mt-8">
            <div className=" text-center">
              <div className="w75  mx-auto">
                <img
                  src={friend?.ami?.photo_profil}
                  alt="photoContact"
                  className="image-preview__image radius50"
                />
              </div>
            </div>
            <h1 className="text-center bleuM500  mt-8">
              {friend?.ami?.prenom} {friend?.ami?.nom}
            </h1>
            <div className="mt-16">
              <div className="d-flex justify-content-between">
                {friend?.etat === "en_attente" ? (
                  <>
                    {" "}
                    <button
                      className="btn-primary-s w-sm-auto lg-w-100 p-14 mr-8"
                      onClick={() => {
                        updateInvitStatus(friend?.idInvitation, "accepte");
                      }}
                    >
                      Accepter comme ami
                    </button>
                    <button
                      className="btn-secondary-s col lg-w-100 p-14 mr-8"
                      onClick={() => {
                        updateInvitStatus(friend?.idInvitation, "refuse");
                      }}
                    >
                      Refuser
                    </button>
                  </>
                ) : friend?.etat === "accepte" ? (
                  <>
                    <button className="btn-secondary-s col-auto p-14 mr-8">
                      <img src={Check} alt="Check" className="mr-4" />
                      Ami(e)
                    </button>
                    <button
                      className="btn-secondary-s w-100 p-14  "
                      onClick={() => {
                        checkdiscussion(friend?.ami?.id);
                      }}
                    >
                      <img src={Message} alt="message" className="mr-4" />
                      Ecrire un message
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
              {friend?.etat === "en_attente" ? (
                <button
                  className="btn-secondary-s  w-100 p-14  mt-16 "
                  onClick={() => {
                    checkdiscussion(friend?.ami?.id);
                  }}
                >
                  <img src={Message} alt="message" className="mr-4" />
                  Ecrire un message
                </button>
              ) : (
                <button
                  className="btn-primary-s w-100 p-14  mt-16"
                  onClick={() => {
                    navigate("/details-covoiturage", {
                      state: { cible: "friend", friend },
                    });
                  }}
                >
                  Proposer un trajet à mon ami(e)
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="mt-24">
          <Tabs tabs={tabs} className="bg-white" />
          <div className="h-xs-100"></div>
        </div>
      </div>
    </div>
  );
};
export default PublicProfil;
