import { useNavigate } from "react-router-dom";
import ConfirmationIcon from "../../assets/images/confirmation.svg";
import Header from "../../components/header";
import { useEffect, useState } from "react";
import Services from "../../services/global.service";
import { HelmetProvider ,Helmet} from "react-helmet-async";


const Confirmation = () => {
  const [connected, setConnected] = useState(null);
  useEffect(() => {
    const auth = localStorage.getItem("token");
    if (auth) {

      Services.getAll("check-token")
        .then((res) => {
          // console.log('check',res.data);
          if (res.data.status === "success") {
            setConnected(true);
          } else {
            setConnected(false);
          }
        })
        .catch((err) => {
          setConnected(false);
        });
    } else {
      return setConnected(false);
    }
  }, [connected]);
  // Handle form submission
  const navigate=useNavigate()

  return (
    <>
    <HelmetProvider>
       <Helmet>
            <title>Page de confirmation </title>
            <meta name="description" content="page de confirmation" />
        </Helmet>
    </HelmetProvider>
   
      <div className="desktop">
      {connected!==null && <Header connected={connected}/>} 
      </div>

      <div className="stepFinal">
        <div className="container-fluid-lg">
         
        </div></div>
        <div className=" col-lg-5 mx-auto text-center">
            <img src={ConfirmationIcon} alt="confirmation icon" />
            <h2 className="px-16 bleuM500 mt-32 text-center">Votre compte a été configuré avec succès !</h2>
          <p className="px-16 mt-8 exergue bleuM500 text-center">
          Vous faites maintenant partie de la communauté des conducteurs solidaires.
          </p>
        
          
         
        </div>
        <div className="bottom-white-block">
          <button
            className="btn-primary-s p-16 w-sm-100"
            onClick={()=>{navigate('/')}}
          >
            C’est parti !
          </button>
        </div>
      
    </>
  );
};

export default Confirmation;
