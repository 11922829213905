import Logo from "../assets/images/logoWhite.svg";
import "../assets/styles/footer.scss";
import Fcb from "../assets/images/fcb.svg";
import X from "../assets/images/x_logo.svg.svg";
import Linkedin from "../assets/images/linkedIn.svg";
import Contact from "./contact";
import { useState } from "react";
const Footer = () => {
  const [open, setOpen] = useState(false);
  return (
    <footer className="">
      {open && <Contact open={open} setOpen={setOpen} />}
      <img src={Logo} alt="logo atchoum white" loading="lazy" />
      <p className="copyright">Atchoum © {new Date().getFullYear()}</p>
      <div className="mt-24">
        <p className=" m-0 ">
          <a href="/cgu" className="link-footer  p-0">Conditions générales d’utilisation</a>
        </p>
        <p className=" mt-8 mb-0">
          {" "}
          <a href="/mentions-legales" className="link-footer p-0">Mentions légales</a>
        </p>
        <p className=" mt-8 mb-0">
          <a className="link-footer  p-0" href="/pc">Politique de confidentialité</a>
        </p>
      </div>
      <div className="mt-24 contact">
        <p className="link-footer"> <a className="link-footer  p-0" href="mailto:contact@atchoum.eu:">contact@atchoum.eu</a></p>
        {/* <p className="link-footer mt-16">0 806 110 444 </p> */}
        <button
          className="link-footer-bold mt-16 cta"
          onClick={() => {
            setOpen(!open);
          }}
        >
          Contactez-nous
        </button>
      </div>
      <div className="mt-16 mb-60">
        <a href="https://www.facebook.com/atchoumofficiel/" target="_blank" rel="noreferrer" className="mr-16"><img src={Fcb} alt="social icons" /></a>
        <a href="https://x.com/Atchoumofficiel?mx=2" className="mr-16" target="_blank" rel="noreferrer" ><img src={X} alt="social icons" /></a>
        <a href="https://www.linkedin.com/company/atchoum/?originalSubdomain=fr" className="mr-16" target="_blank" rel="noreferrer" ><img src={Linkedin} alt="Linkedin icons" /></a>
      
      </div>
      {/* <div className="h-xs-100"></div> */}
    </footer>
  );
};
export default Footer;
