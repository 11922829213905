// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import Header from "../components/header";
import Layout from "./layout";

const Ml = () => {
  return (
    <Layout>
      <HelmetProvider>
        <Helmet>
            <title>Mentions légales </title>
            <meta name="description" content="page Mentions légales" />
        </Helmet>
      </HelmetProvider>
      
        <div className="p-16">
        <h1 className="bleuM500 mt-24"> Mentions légales</h1>
      <p className="exergue  mt-24">SIRET</p>
      <p className="current-text mt-8">831 044 763 00017</p>
      <p className="exergue  mt-24">Confidentialité</p>
      <p className="current-text mt-8">
        Atchoum n’enregistre pas d’informations personnelles permettant
        l’identification, à l’exception des formulaires que l’utilisateur est
        libre de remplir. Ces informations ne seront pas utilisées sans votre
        accord, nous les utiliserons seulement pour vous adresser des courriers,
        des brochures, des devis ou vous contacter. Les informations recueillies
        sur les sites bénéficient de la protection de la loi « Informatique et
        Libertés » n° 78-17 du 06 janvier 1978. Elles bénéficient d’un droit
        d’accès, de rectification, d’opposition à communication et de
        suppression sur simple demande à Atchoum. Atchoum pourra procéder à des
        analyses statistiques sans que celles-ci soient nominatives et pourra en
        informer des tiers (organismes d’évaluation de fréquentation) sous une
        forme résumée et non nominative.
      </p>
      <p className="exergue  mt-24">Liens hypertexte :</p>
      <p className="current-text mt-8">
        Atchoum ne contrôle pas les sites en connexion avec le sien, et ne
        saurait donc être responsable de leur contenu. Les risques liés à
        l’utilisation de ces sites incombent pleinement à l’utilisateur. Il se
        conformera à leurs conditions d’utilisation. – Propriétaire du site :
        Atchoum – Éditeur du site :Atchoum
      </p>
      <p className="exergue  mt-24">Hébergement du site atchoum.eu</p>
      <p className="current-text mt-8">
        Le site atchoum.eu est hébergé par Revolucy.
      </p>
      <p className="exergue mt-24">Important :</p>
      <p className="current-text mt-8">
        Les droits de propriété intellectuelle afférent au site atchoum.eu
        appartiennent à Atchoum. Toute utilisation, reproduction, diffusion,
        commercialisation, modification de toute ou partie du site, sans
        l’autorisation de Atchoum est prohibée et pourra entraînée des actions
        et poursuites judiciaires telles que notamment prévues par le code de la
        propriété intellectuelle et/ou le code civil.
      </p>{" "}
        </div>
     
      </Layout>
  );
};
export default Ml;
