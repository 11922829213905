import { useEffect, useState } from "react";
import ReturnIcon from "../../assets/images/return.svg";
import Header from "../../components/header";
import "./profil.scss"; // Add your custom styles
import Services from "../../services/global.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { calculateAge, formatPhoneNumber } from "../../utils/utils";
import InputMask from "react-input-mask";
import { HelmetProvider,Helmet } from "react-helmet-async";
const Informations = () => {
  const [error, setError] = useState("");
  const [adresse, setAdresse] = useState("");
  const [adr, setAdr] = useState(null);
  const [LatLng, setLatLng] = useState(null);
  const [autorisation, setAutorisation] = useState(null);

  const navigate = useNavigate();
  // State to manage edit mode for each field
  const [editMode, setEditMode] = useState({
    prenom: false,
    nom: false,
    email: false,
    telephone: false,
    adresse: false,
    date_naissance: false,
  });
  // State to store form data
  const [formData, setFormData] = useState({
    prenom: "",
    nom: "",
    email: "",
    telephone: "",
    adresse: "",
    date_naissance: null,
  });
  const handleChangeAddress = (address, type) => {
    if (type === "adresse") {
      setAdresse(address);
      setError("");
      setLatLng(null);
    }
  };

  const handleSelect = async (address, type) => {
    if (type === "adresse") {
      setAdresse(address);
    }

    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;

      const postalCode = addressComponents.find((component) =>
        component.types.includes("postal_code")
      );

      const street = addressComponents.find((component) =>
        component.types.includes("route")
      );
      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      );
      // console.log({ street, city, postalCode });
      if (postalCode && city) {
        const latLng = await getLatLng(results[0]);
        if (latLng) {
          if (type === "adresse") {
            setLatLng(latLng);
            setAdr({ postalCode, street, city });
          }
        } else {
          console.error("Error: Unable to get latitude and longitude");
        }
      } else {
        const errorMessage =
          "Adresse sélectionnée incomplète. Code postal ou rue manquant.";
        if (type === "adresse") {
          setError(errorMessage);
          setLatLng(null);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const searchOptions = {
    componentRestrictions: { country: ["fr"] },
    types: ["geocode", "establishment"],
  };
  const getMyProfil = () => {
    const idUser = localStorage.getItem("userData");
    // console.log("userData", idUser);
    Services.getOne(idUser, "users")
      .then((res) => {
        const userData = res?.data?.data;

        // Set formData with the user data
        setFormData({
          prenom: userData?.prenom || "", // Use API value or default empty
          nom: userData?.nom || "",
          email: userData?.email || "",
          telephone: userData?.telephone || "",
          adresse: userData?.adresse || "",
          date_naissance: new Date(userData?.date_naissance)?.toLocaleDateString(
            "fr-FR",
            {
              day: "numeric",
              year: "numeric",
              month: "numeric",
            }
          ), // Ensure date is formatted
        });
       // setAutorisation(userData?.autorisationParental);
        setAdresse(userData.adresse);
        setLatLng({ lat: userData.adresse_lat, lng: userData.adresse_long });
        // console.log(userData);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const calculateAgeFormatted = (birthDate) => {
    // console.log({birthDate})
    if (!birthDate) return null;
    const today = new Date();
    const parts = birthDate.split('/'); // Assuming input is DD/MM/YYYY
    if (parts.length !== 3) return false;
    const formattedValue = `${parts[2]}-${parts[1]}-${parts[0]}`; // YYYY-MM-DD
    const birth = new Date(formattedValue);
    let age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    // console.log({age})
    return age;
  };
  useEffect(() => {
    getMyProfil();
  }, []);
  // State to store original form data before editing
  const [originalData, setOriginalData] = useState(formData);

  // Handle changes to input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log({ name, value });
    setFormData({ ...formData, [name]: value });
  };

  // Toggle edit mode for specific fields
  const toggleEditMode = (field) => {
    setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleSave = (field) => {
    // console.log(`Saved ${field}:`, formData[field]);
  
    const formDataToSend = new FormData();
  
    // Add the common data to FormData
    formDataToSend.append(field, formData[field]);
  
    // Handle special cases
    if (field === "telephone") {
      const regex = /^(?:\+33|0033|0)[1-9]\d{8}$/;
      if (!regex.test(formData.telephone)) {
        toast.error("Numéro de téléphone invalide.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          className: "custom-toast-error",
        });
        return;
      }
    }
  
    if (field === "adresse") {
      if (!LatLng || !adresse) {
        toast.error("Adresse invalide.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          className: "custom-toast-error",
        });
        return;
      }
  
      formDataToSend.append("adresse_lat", LatLng.lat);
      formDataToSend.append("adresse_long", LatLng.lng);
      formDataToSend.append("adresse", adresse);
      formDataToSend.append("postalCode", adr?.postalCode?.long_name || "");
      formDataToSend.append("street", adr?.street?.long_name || "");
      formDataToSend.append("city", adr?.city?.long_name || "");
    }
  
    if (field === "date_naissance") {
      if (calculateAgeFormatted(formData?.date_naissance) < 13) {
        toast.error("Enfant moins de 13 ans n'ont pas autorisé !!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          className: "custom-toast-error",
        });
        return;
      }
  
      // formDataToSend.append("date_naissance", formData?.date_naissance);
      // console.log({autorisation})
      if (autorisation) {
        formDataToSend.append("autorisationParental", autorisation);
      }
    }
  
    // Send the FormData object via the service
    Services.create(formDataToSend, "update_user")
      .then((res) => {
        // console.log(res);
        toast.success("Votre opération a réussi !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          className: "custom-toast",
        });
  
        // Update state to reflect the new value
        if (field === "adresse") {
          setFormData((prev) => ({
            ...prev,
            adresse: adresse,
          }));
          setOriginalData((prev) => ({
            ...prev,
            adresse: adresse,
          }));
        }
  
        toggleEditMode(field);
      })
      .catch((err) => {
        // console.log(err);
        toast.error(err?.response?.data?.message || "Une erreur s'est produite.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          className: "custom-toast-error",
        });
      });
  };
  
  // remove numeric value
  const handleKeyDown = (e) => {
    const invalidKeys = [
      ...Array.from({ length: 10 }, (_, i) => `${i}`), // Numbers 0-9
    ];

    // Allow navigation keys (Backspace, Delete, Arrow keys)
    if (
      invalidKeys.includes(e.key) &&
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(e.key)
    ) {
      e.preventDefault();
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // console.log({ file });
    if (file) {
      setAutorisation(file);
    } else {
      setAutorisation(null); // Réinitialise si aucune image n'est sélectionnée
    }
  };

  // Cancel editing and revert changes
  const handleCancel = (field) => {
    setFormData(originalData); // Revert to original data
    toggleEditMode(field); // Exit edit mode
  };
  const hasChanged = (field) => {
    return (
      formData[field] !== originalData[field] &&
      formData[field].trim().length > 0
    );
  };
  return (
    <>
    <HelmetProvider>
       <Helmet>
            <title>Informations personnelles</title>
            <meta name="description" content="mes informations personnelles" />
        </Helmet>
    </HelmetProvider>
        
      <div className="desktop">
        <Header connected={true} />
      </div>
      <div className="bg-grey">
        <div className="bg-atchoum-profil">
          <div className="container-fluid-lg">
            <h1 className="text-white p-16 d-flex align-items-center mb-100">
              <button onClick={() => navigate(-1)} className="cta">
                <img src={ReturnIcon} className="mr-8" alt="return icon" />
              </button>
              Informations personnelles
            </h1>
          </div>

          <div className="p-16 mt-32 col-lg-4 mx-auto">
            <div className="row">
              {/* Prénom Field */}
              <div className="col-lg-12 col-xs-12 py-16 border-b">
                <div>
                  {editMode.prenom ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <p className="current-text">Prénom</p>
                        <button
                          className="cta"
                          onClick={() => handleCancel("prenom")}
                        >
                          Annuler
                        </button>
                      </div>
                      <div className="mt-8">
                        <input
                          type="text"
                          name="prenom"
                          value={formData.prenom}
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                          className="p-16 floating-input"
                        />
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <button
                          className="btn-primary-s w-100"
                          onClick={() => handleSave("prenom")}
                          disabled={!hasChanged("prenom")}
                        >
                          Enregistrer
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="exergue my-0">Prénom</p>
                        <span className="current-text mt-8">
                          {formData.prenom}
                        </span>
                      </div>
                      <button
                        className="cta"
                        onClick={() => {
                          setOriginalData(formData); // Save original data before editing
                          toggleEditMode("prenom");
                        }}
                      >
                        Modifier
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {/* Nom Field */}
              <div className="col-lg-12 col-xs-12 py-16 border-b">
                <div>
                  {editMode.nom ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <p className="current-text">Nom</p>
                        <button
                          className="cta"
                          onClick={() => handleCancel("nom")}
                        >
                          Annuler
                        </button>
                      </div>
                      <div className="mt-8">
                        <input
                          type="text"
                          name="nom"
                          value={formData.nom}
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                          className="p-16 floating-input"
                        />
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <button
                          className="btn-primary-s w-100"
                          onClick={() => handleSave("nom")}
                          disabled={!hasChanged("nom")}
                        >
                          Enregistrer
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="exergue my-0">Nom</p>
                        <span className="current-text mt-8">
                          {formData.nom}
                        </span>
                      </div>
                      <button
                        className="cta"
                        onClick={() => {
                          setOriginalData(formData); // Save original data before editing
                          toggleEditMode("nom");
                        }}
                      >
                        Modifier
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {/* Email Field */}
              <div className="col-lg-12 col-xs-12 py-16 border-b">
                <div>
                  {editMode.email ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <p className="current-text">Adresse e-mail</p>
                        <button
                          className="cta"
                          onClick={() => handleCancel("email")}
                        >
                          Annuler
                        </button>
                      </div>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="p-16 floating-input mt-8"
                      />
                      <div className="d-flex justify-content-between mt-2">
                        <button
                          className="btn-primary-s w-100"
                          onClick={() => handleSave("email")}
                          disabled={!hasChanged("email")}
                        >
                          Enregistrer
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="exergue my-0">Adresse email</p>
                        <span className="current-text mt-8">
                          {formData.email}
                        </span>
                      </div>
                      <button
                        className="cta"
                        onClick={() => {
                          setOriginalData(formData); // Save original data before editing
                          toggleEditMode("email");
                        }}
                      >
                        Modifier
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {/* Téléphone Field */}
              <div className="col-lg-12 col-xs-12 py-16 border-b">
                <div>
                  {editMode.telephone ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <p className="current-text">Téléphone</p>
                        <button
                          className="cta"
                          onClick={() => handleCancel("telephone")}
                        >
                          Annuler
                        </button>
                      </div>
                      <input
                        type="text"
                        name="telephone"
                        value={formData.telephone}
                        onChange={handleChange}
                        className="p-16 floating-input mt-8"
                      />
                      <div className="d-flex justify-content-between mt-2">
                        <button
                          className="btn-primary-s w-100"
                          onClick={() => handleSave("telephone")}
                          disabled={!hasChanged("telephone")}
                        >
                          Enregistrer
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="exergue my-0">Téléphone</p>
                        <span className="current-text mt-8">
                          {formatPhoneNumber(formData.telephone)}
                        </span>
                      </div>
                      <button
                        className="cta"
                        onClick={() => {
                          setOriginalData(formData); // Save original data before editing
                          toggleEditMode("telephone");
                        }}
                      >
                        Modifier
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {/* naissance Field */}
              <div className="col-lg-12 col-xs-12 py-16 border-b">
                <div>
                  {editMode.date_naissance ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <p className="current-text">Date de naissance</p>
                        <button
                          className="cta"
                          onClick={() => handleCancel("date_naissance")}
                        >
                          Annuler
                        </button>
                      </div>
                      <div className="mt-8">
                        
                        <InputMask
                          mask="99/99/9999"
                          name="date_naissance"
                          placeholder="JJ/MM/AAAA"
                          value={formData?.date_naissance}
                          onChange={handleChange}
                          className={`floating-input radius-24 `}
                          id="dateNaissance"
                        />
                      </div>
                      {calculateAgeFormatted(
                        formData?.date_naissance
                      ) !== null &&
                      calculateAgeFormatted(
                          formData?.date_naissance
                        ) >= 13 &&
                        calculateAgeFormatted(
                          formData?.date_naissance
                        ) < 18 && (
                          <div className="floating-input-container mt-16">
                            <div
                              className="btn-secondary-s p-14 w-100"
                              onClick={() =>
                                document.getElementById("autorisation").click()
                              }
                            >
                              {autorisation?.name ||
                                "Ajouter une autorisation parentale"}
                            </div>
                            <input
                              type="file"
                              id="autorisation"
                              // accept="image/*"
                              // multiple
                              accept="image/*,application/pdf"
                              onChange={handleImageChange}
                              style={{ display: "none" }}
                            />
                          </div>
                        )}

                      <div className="d-flex justify-content-between mt-2">
                        <button
                          className="btn-primary-s w-100"
                          onClick={() => handleSave("date_naissance")}
                          disabled={!hasChanged("date_naissance")}
                        >
                          Enregistrer
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="text-start">
                        <p className="exergue my-0">Date de naissance</p>
                        <span className="current-text mt-8">
                          {formData?.date_naissance}
                        </span>
                      </div>
                      <button
                        className="cta"
                        onClick={() => {
                          setOriginalData(formData); // Save original data before editing
                          toggleEditMode("date_naissance");
                        }}
                      >
                        Modifier
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {/* Adresse Field */}
              <div className="col-lg-12 col-xs-12 py-16 border-b">
                <div>
                  {editMode.adresse ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <p className="current-text">Adresse</p>
                        <button
                          className="cta"
                          onClick={() => handleCancel("adresse")}
                        >
                          Annuler
                        </button>
                      </div>
                      <div className="mt-8">
                        <Autocomplete
                          value={adresse}
                          searchOptions={searchOptions}
                          onChange={(addr) =>
                            handleChangeAddress(addr, "adresse")
                          }
                          onSelect={(addr) => handleSelect(addr, "adresse")}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="floating-input-container ">
                              <input
                                {...getInputProps({
                                  placeholder: "",

                                  className: ` floating-input  ${
                                    error ? "danger-input" : ""
                                  }`,
                                })}
                                id="floatingAdr"
                                name="adresse"
                              />
                              <label
                                htmlFor="floatingAdr"
                                className="floating-label"
                              >
                                Adresse
                              </label>
                              <div
                                className={
                                  suggestions.length > 0
                                    ? "container-autocomplete"
                                    : ""
                                }
                              >
                                {loading ? <div>Loading...</div> : null}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#41B6E6"
                                      : "#fff",
                                  };
                                  return (
                                    <div
                                      className="mapItem"
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </Autocomplete>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <button
                          className="btn-primary-s w-100"
                          onClick={() => handleSave("adresse")}
                          // disabled={!hasChanged("adresse")}
                        >
                          Enregistrer
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="exergue my-0">Adresse</p>
                        <span className="current-text mt-8">
                          {formData.adresse}
                        </span>
                      </div>
                      <button
                        className="cta"
                        onClick={() => {
                          setOriginalData(formData); // Save original data before editing
                          toggleEditMode("adresse");
                        }}
                      >
                        Modifier
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Informations;
