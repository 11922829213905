import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import IconClose from '../assets/images/iconClose.svg'
import Services from "../services/global.service";
import { toast } from "react-toastify";
const Contact = ({ open, setOpen }) => {
  // Validation schema for the form
  const validationSchema = Yup.object({
    prenom: Yup.string().required("Le prénom est requis"),
    nom: Yup.string().required("Le nom est requis"),
    nom_collectivite: Yup.string().required("Le nom de la collectivité est requis"),
    email: Yup.string()
      .email("Adresse e-mail invalide")
      .required("L'adresse e-mail est requise"),
    telephone: Yup.string().required("Le numéro de téléphone est requis"),
    message: Yup.string().required("Le message est requis"),
  });
const saveContact=(values, { setSubmitting})=>{
  // console.log({values})

  Services.create(values,'contact')
  .then((res)=>{
    // console.log(res)
    toast.success('Merci pour votre message. Nous vous recontactons dans les plus brefs délais', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      onClose: ()=>setOpen(false),
     // theme: 'light', // Choose theme (light/dark)
      className: 'custom-toast'

    });
    setOpen(!open)
    setSubmitting(false)

  })
  .catch((err)=>{
    // console.log(err)
    toast.error(err?.response?.data?.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      onClose: ()=>setOpen(false),
     // theme: 'light', // Choose theme (light/dark)
      className: 'custom-toast'

    });
  })
  
}
  return (
    <>
      <Modal
        show={open}
        onHide={() => {
          setOpen(false);
        }}
      >
        <Modal.Header className="border-none d-flex justify-content-between">
          <Modal.Title className=" ">
            <h1 className="bleu600">Contactez-nous</h1> 
            </Modal.Title>
            <button className="cta" onClick={()=>{setOpen(false)}}>
              <img src={IconClose} alt="icon close" loading="lazy"/></button>
         
        </Modal.Header>

        <Modal.Body>
          <Formik
            initialValues={{
              prenom: "",
              nom: "",
              nom_collectivite: "",
              email: "",
              telephone: "",
              message: "",
            }}
            validationSchema={validationSchema}
            onSubmit={saveContact}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group floating-input-container">
                  <Field
                    type="text"
                    name="prenom"
                    className="floating-input radius-top"
                    placeholder=""
                  />
                  <label htmlFor="prenom" className="floating-label">
                    Prénom
                  </label>
                </div>
               

                <div className="form-group floating-input-container">
                  <Field
                    type="text"
                    name="nom"
                    className="floating-input radius-bottom"
                    placeholder=""
                  />
                  <label htmlFor="nom" className="floating-label">
                    Nom
                  </label>
                </div>
                <ErrorMessage
                  name="nom"
                  component="div"
                  className="text-danger"
                />
               <ErrorMessage
                  name="prenom"
                  component="div"
                  className="text-danger"
                />
                <div className="form-group floating-input-container  mt-16">
                  <Field
                    type="text"
                    name="nom_collectivite"
                    className="floating-input"
                    placeholder=""
                  />
                  <label htmlFor="nom_collectivite" className="floating-label">
                  Commune ou EPCI concerné
                  </label>
                </div>
                <ErrorMessage
                  name="nom_collectivite"
                  component="div"
                  className="text-danger"
                />

                <div className="form-group floating-input-container mt-16">
                  <Field
                    type="email"
                    name="email"
                    className="floating-input radius-top"
                    placeholder=""
                  />
                  <label htmlFor="email" className="floating-label">
                    Adresse e-mail
                  </label>
                </div>
                

                <div className="form-group floating-input-container">
                  <Field
                    type="tel"
                    name="telephone"
                    className="floating-input radius-bottom"
                    placeholder=""
                  />
                  <label htmlFor="telephone" className="floating-label">
                    Numéro de téléphone
                  </label>
                </div>
                <ErrorMessage
                  name="telephone"
                  component="div"
                  className="text-danger"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
                <div className="form-group floating-input-container  mt-16">
                  <Field
                    as="textarea"
                    name="message"
                    className="floating-input"
                    placeholder=""
                    rows="4"
                  />
                  <label htmlFor="message" className="floating-label">
                    Votre message
                  </label>
                </div>
                <ErrorMessage
                  name="message"
                  component="div"
                  className="text-danger"
                />

                <div className="form-group mt-3">
                  <button
                    type="submit"
                    className="btn-primary-s w-100"
                    disabled={isSubmitting}
                  >
                    Envoyer
                  </button>
                </div>
               <div className="h-xs-100"></div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal> 
      
    </>
  );
};

export default Contact;
