import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import ArrowUp from '../assets/images/arrow-up.svg'
// import './Faq.css'; // Optional, for custom styling

const Faq = () => {
  // Array of states to manage multiple collapses
  const [open, setOpen] = useState([false, false, false]); // Assume 3 FAQs for example

  const toggleCollapse = (index) => {
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[index] = !newOpen[index]; // Toggle the selected FAQ collapse
      return newOpen;
    });
  };
  const Faqs=[
    { title: 'C’est quoi Atchoum ?', content: 'Answer to question 1.' },
    { title: 'En quoi se différencie Atchoum par rapport aux solutions existantes ?', content: 'Answer to question 2.' },
    { title: "J'ai besoin de me déplacer, comment faire une demande de trajet ?", content: 'Answer to question 3.' },
    { title: "Comment payer ?", content: 'Answer to question 4.' },
    { title: "Peut-on mettre en place Atchoum sans être une Autorité Organisatrice de la Mobilité (AOM) ?", content: 'Answer to question 5.' },
  ]

  return (
    <div className='container w-50-lg mt-42'>
      <h2>FAQ</h2>
      <div className="mt-16">
      {Faqs?.map((faq, index) => (
        <div key={index} className={`faq-item ${open[index] ? 'open-faq' : ''}`}>
          <button
            onClick={() => toggleCollapse(index)}
            aria-controls={`faq-collapse-text-${index}`}
            aria-expanded={open[index]}
            className={`faq-title w-100 d-flex justify-content-between align-items-center ${open[index] ? 'border-b pb-16' : ''}`}
          >
            {faq.title}
            <span className={`arrow ${open[index] ? 'open' : ''}`}>
              <img src={ArrowUp} alt="arrow up icon" loading="lazy" /></span>
          </button>
          <Collapse in={open[index]}>
            <div id={`faq-collapse-text-${index}`} className="faq-content mt-16">
              {faq.content}
            </div>
          </Collapse>
        </div>
      ))}
      </div>
      
      <div className="mt-24">
       <a href="/" className="cta ">Voir plus de questions</a>  
      </div>
     
    </div>
  );
};

export default Faq;
