import { ErrorMessage, Formik } from "formik";
import ReturnIcon from "../../assets/images/return.svg";
import Header from "../../components/header";
import { Form, FormCheck } from "react-bootstrap";
import { useEffect, useState } from "react";
import Services from "../../services/global.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { HelmetProvider ,Helmet} from "react-helmet-async";

const Notifs = () => {
  const navigate=useNavigate()
 const [email,setEmail]=useState(false)
 const [sms,setSms]=useState(false)  
  const getMyProfil = async () => {
    const idUser = localStorage.getItem('userData');
    try {
      const res = await Services.getOne(idUser, 'users');
      setEmail(res?.data?.data?.email_notif)
      setSms(res?.data?.data?.sms_notif)
      
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getMyProfil();
  }, []);
  const handleSwitchChange = (type) => {
    if(type==="email") {
      
      Services.create({email_notif:!email},'update_user')
      .then((res)=>{
        toast.success('paramètres modifié avec succès', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast'
  
        });
      })
      setEmail((prevState) => !prevState);
    }
    if(type==="sms") {
    
      Services.create({sms_notif:!sms},'update_user')
      .then((res)=>{
        toast.success('paramètres modifié avec succès', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast'
  
        });
        setSms((prevState) => !prevState);
      })
    
    }
  };
  return (
    <>
    <HelmetProvider>
      <Helmet>
            <title>Page notifications </title>
            <meta name="description" content="paramètres de notifications" />    
        </Helmet>
    </HelmetProvider>
       
      <div className="desktop">
        <Header connected={true}/>
      </div>
      <div className="bg-grey">
        <div className="bg-atchoum-profil">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex">
             <button onClick={() => navigate(-1)} className='cta'><img src={ReturnIcon} className="mr-8" alt="return icon" /></button>
            Notifications
          </h1>
        </div>
        </div>
        <div className="px-16 mt-8">
            <p className="subtitle bleuM500">
            Je souhaite recevoir mes notifications
            </p>
          <div className="col-12  border-b py-16">
            <div className="custom-switch justify-content-between custom-switch-label-io d-flex align-items-center">
            <label className="bleuM500 legend" htmlFor="custom-switch">
                Par e-mail
              </label>
              <FormCheck
                type="switch"
                id="custom-switch"
                className="form-switch mr-8"
                checked={email}
                onChange={()=>handleSwitchChange("email")} // Don't use `defaultValue` here
              />
              
            </div>
          </div>
          <div className="col-12 py-16">
            <div className="custom-switch justify-content-between custom-switch-label-io d-flex align-items-center">
            <label className="bleuM500 legend" htmlFor="custom-switch">
                Par sms
              </label>
              <FormCheck
                type="switch"
                id="custom-switch"
                className="form-switch mr-8"
                checked={sms}
                onChange={()=>handleSwitchChange("sms")} // Don't use `defaultValue` here
              />
             
            </div>
          </div>
        </div>
     
      </div>
      
    </>
  );
};
export default Notifs;
