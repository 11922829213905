import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import IconClose from '../assets/images/iconClose.svg';
import Form from 'react-bootstrap/Form';
import Services from '../services/global.service';
import { toast } from 'react-toastify';

function ModalSolidaire({ open, setOpen}) {
  
 const [raison,setRaison]=useState('')


 
  const handleUpdate=()=>{
    const data={
      conducteur_solidaire:false,
      raison:raison
    }
    Services.create(data,`update_user`)
    .then((res)=>{
      // console.log(res)
     setOpen(!open)

    })
    .catch((err)=>{
      toast.error(err?.response?.data?.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
       // theme: 'light', // Choose theme (light/dark)
        className: 'custom-toast-error'

      });
      // console.log(err)
    })
  }

  return (
    <Modal show={open} onHide={() => setOpen(false)} centered>
      <Modal.Header className="border-0 d-flex justify-content-between align-items-start">
        <h2 className="bleuM500">Vous souhaitez ne plus être conducteur solidaire ?</h2>
        <button className="cta" onClick={() => setOpen(false)}>
          <img src={IconClose} alt="icon close" />
        </button>
      </Modal.Header>

      <Modal.Body className='m-0'>
        <p className="exergue bleuM500">
        Nous sommes navrés de vous voir quitter l’aventure. 
        Pourriez-vous nous indiquer la raison de votre départ ?
        </p>
        <Form className='mt-24'>
            <div  className="floating-input-container mb-3">
            <input
              type="text"
              value={raison}
              onChange={(e) => setRaison(e.target.value)}
              className={`floating-input p-16`} 
              placeholder=" " // important for floating effect
            />
            <label className="floating-label">
            Quelle est la raison ?
            </label>
            </div>
           
         
        </Form>
      </Modal.Body>

      <Modal.Footer className="border-0">
        <button className="btn-primary-s p-16 w-100" onClick={handleUpdate}>
          Je confirme
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalSolidaire;
