import Header from "../components/header";
import "../assets/styles/home.scss";
import BlocSearch from "../components/blocSearch";
import Ccm from "../components/ccm";
import BlocChiffres from "../components/blocChiffres";
import Testimonials from "../components/testimonials";
import Footer from "../components/footer";
import Faq from "../components/faq";
import BlogHome from "../components/blog";
import Recompense from "../components/recompense";
import Partenaires from "../components/partenaires";
import Contact from "../components/contact";
import { useEffect, useState } from "react";
// import "core-js/stable/atob";
import TopBar from "../components/topBar";
import Services from "../services/global.service";
import { useLocation } from "react-router-dom";
import { useMyContext } from "../context";
import { HelmetProvider, Helmet } from 'react-helmet-async';
const Home = () => {
  const [open, setOpen] = useState(false);
  const [connected, setConnected] = useState(null);
  const location = useLocation();
  const { userData } = useMyContext();
  const dataSearch = location?.state?.searchData;
  useEffect(() => {
    const auth = userData ? userData?.token : localStorage.getItem("token");
    // console.log({auth})
    if (auth) {
      Services.getAllWithToken("check-token", auth)
        .then((res) => {
          //  console.log('check',res.data);
          if (res.data.status === "success") {
            setConnected(true);
          } else {
            setConnected(false);
          }
        })
        .catch((err) => {
          setConnected(false);
        });
    } else {
      return setConnected(false);
    }
  }, [connected, userData]);
  return (
    <>
     <HelmetProvider>
      <Helmet>
        <title>Accueil</title>
        <meta
          name="description"
          content="Site de covoiturage normal et solidaire france"
        />
      </Helmet>
      </HelmetProvider>
      {open && <Contact open={open} setOpen={setOpen} />}
      {connected !== null && <Header connected={connected} />}
      <BlocSearch dataSearch={dataSearch} />
      <Ccm />
      <BlocChiffres />
      <Testimonials />
      <Faq />
      <BlogHome />
      <Recompense />
      <Partenaires />
      <button
        className="cta text-underline mt-42 w-75"
        onClick={() => {
          setOpen(!open);
        }}
      >
        Vous êtes une collectivité ? Contactez-nous
      </button>
      <Footer />
      {connected && <TopBar />}
    </>
  );
};
export default Home;
