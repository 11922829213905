import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowUp from "../assets/images/Arrow-right.svg";

const TrajetGroup = (props) => {
  // console.log({props})
  const [timeDept, setTimeDept] = useState('');
  const [timeRetour, setTimeRetour] = useState('');
  const [formattedDate, setFormattedDate] = useState('');
const navigate=useNavigate()
  useEffect(() => {
    if(props?.trajetType==="retour"){
      if (props?.trajet?.dateRetour) {
        formatDate(props?.trajet?.dateRetour);
      } 
      else if(props?.trajet?.isFlexibleDest){
        setFormattedDate('')
      }
    }
    else if(props?.trajetType==="aller"){
      if (props?.trajet?.dateDepart) {
        // console.log('im hereee')
        formatDate(props?.trajet?.dateDepart);
      } 
      else if(props?.trajet?.isFlexibleDep){
        // console.log('im hereee')
        setFormattedDate('')
      }
      else if(props?.trajet?.Trajetregulier ){
        setFormattedDate('Trajet régulier')
      }
    }
    // else{
     
    //   formatDate('');
    
    // }
    
  }, [props.trajet?.dateDepart]); // Only run when dateDepart changes

  const formatDate = (dateString) => {
    // Parse the date string to a Date object
    const date = new Date(dateString);

    // Get individual components using Date methods
    const day = date.getDate(); // Day of the month
    const month = date.getMonth(); // Month (0-11)
    const dayOfWeek = date.getDay(); // Day of the week (0-6)
    const hours = date.getHours(); // Hours (0-23)
    const minutes = date.getMinutes(); // Minutes (0-59)

    // Day and month names
    const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const monthNames = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

    // Get the names
    const dayName = daysOfWeek[dayOfWeek];
    const monthName = monthNames[month];
    const durationHours = parseInt(props?.trajet?.duration?.split("h")[0])|| 0;
    const durationMinutes = parseInt(props?.trajet?.duration?.split("h")[1])|| 0;
     // Calculate the return time
  const totalMinutes = minutes + durationMinutes;
  const totalHours = hours + durationHours + Math.floor(totalMinutes / 60); // Account for extra hours from minutes
  const finalMinutes = totalMinutes % 60; // Remainder of minutes after an hour
  const finalHours = totalHours >23 ? totalHours % 24 : totalHours; // Remainder of hours after a day
  
    // Set formatted time and date
   // setTimeRetour(`${finalHours.toString().padStart(2, "0")}h${finalMinutes.toString().padStart(2, "0")}`);
    setTimeDept(`${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`);
    setTimeRetour(`${(parseInt(hours)+durationHours).toString().padStart(2, "0")}h${(parseInt(minutes)+durationMinutes).toString().padStart(2, "0")}`)
    setFormattedDate(`${dayName} ${day.toString().padStart(2, '0')} ${monthName}`);
  };

  return (
    <div className="mt-8" onClick={()=>navigate(props?.trajet?.conducteur==localStorage.getItem('userData')?`/mes-trajets/${props?.trajet?.id}`:`/le-trajet/${props?.trajet?.id}`)} >
      <div className="bloc-trajet le-trajet">
        <div className="d-flex justify-content-between align-items-center">
          <div className="subtitle bleuM500"> {formattedDate}</div>
          <div className="">
           
              <p className="subtitle bleuM500 mb-0">{props?.trajet?.prixTrajet} €</p>
        
          </div>
        </div>
        <div className="trip-details mt-16">
          <div className="trip-time">
            <span className="exergue mb-20"> {timeDept}</span>
            <span className="legend bleu400 mb-20">{props?.trajet?.duration}</span>
            <span className="exergue">{timeRetour}</span>
          </div>
          <div className="ml-8 trip-address">
            {props.trajetType==="retour" 
            ?<>
              <p className="legend mb-0 bleuM500">{props?.trajet?.adresseFinal}</p>
            <p className="legend mb-0 bleuM500">{props?.trajet?.adresseDepart}</p>
            </>
          :
          <><p className="legend mb-0 bleuM500">{props?.trajet?.adresseDepart}</p>
            <p className="legend mb-0 bleuM500">{props?.trajet?.adresseFinal}</p></>}
           
          </div>
          
        </div>
           {
          ( props?.trajet?.conducteur==localStorage.getItem('userData')  &&
            props?.trajet?.trajet_reservation?.filter((el) => el.status === "onHold").length!==0 
           // && props?.from==="group"
           )
             && <div className="success mt-8">
            Acceptez ou refusez la proposition de trajet <img src={ArrowUp} alt="arrow up icon" />
           </div>}
           {
          ( props?.trajet?.conducteur==localStorage.getItem('userData')  &&
            props?.trajet?.trajet_reservation?.length===0 
            && props?.from==="ami" )
             && <div className="blue-tag mt-8">
            Votre ami(e) n’a pas encore accepté le trajet <img src={ArrowUp} alt="arrow up icon" />
           </div>}
        {(props?.trajet?.isFlexibleDep && !props.retour && props.trajetType!=="retour" ) ? <div className="isFlexible mt-16">
            Le passager est flexible dans ses disponibilités  {props?.trajet?.horaires[0]?.selectedDayDept} {props?.trajet?.horaires[0]?.selectedPeriodsDept}. Veuillez le contacter pour en discuter.
            </div>
         :''}
          {props?.trajet?.isFlexibleDest && props.trajetType==="retour"  ? <div className="isFlexible mt-16">
                  Le passager est flexible dans ses disponibilités {props?.trajet?.horaires[0]?.selectedDayDest} {props?.trajet?.horaires[0]?.selectedPeriodsDest}. Veuillez le contacter pour en discuter.
                  </div>
          :''}
      </div>
    </div>
  );
};

export default TrajetGroup;
