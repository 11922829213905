import React, { createContext, useContext, useState } from 'react';
// import AuthService from './services/auth.service';

// Create a context
const MyContext = createContext();

// Create a provider component
export const MyProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
  return <MyContext.Provider value={{ userData, setUserData  }}>{children}</MyContext.Provider>;
};

// Custom hook to use the context value
export const useMyContext = () => useContext(MyContext);
