import { useEffect, useState } from "react";
import moment from "moment";
import ReturnIcon from "../../assets/images/return.svg";
import Delete from "../../assets/images/Delete.svg";
import Check from "../../assets/images/check-orange.svg";
import EyeOpened from "../../assets/images/open-eye.svg";
import Header from "../../components/header";
import Services from "../../services/global.service";
import ShowDocModal from "../../components/showDoc";
import { toast } from "react-toastify";
import ModalType from "../../components/modalType";
import { useNavigate } from "react-router-dom";
import { validateFiles } from "../../utils/utils";
import { HelmetProvider, Helmet } from "react-helmet-async";

const Docs = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [triggerFileInput, setTriggerFileInput] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [type, setType] = useState("cin");
  const [url, setUrl] = useState("");
  const [docs, setDocs] = useState({
    justificatif: { recto: null, verso: null },
    permis: { recto: null, verso: null },
    carteGrise: { recto: null, verso: null },
    assurance: { recto: null, verso: null },
  });

  const isSubmitDisabled = Object.values(docs).some(
    (files) => !files.recto && !files.verso
  );

  // Handle image change for each document (only one file per side)
  const handleImageChange = (e, docType, side) => {
    const file = e.target.files[0]; // Only take the first file

    if (file) {
      setDocs((prevDocs) => ({
        ...prevDocs,
        [docType]: {
          ...prevDocs[docType],
          [side]: file, // Replace the existing file with the new one
        },
      }));
    }
  };

  // Fetch documents from the server
  const getDocs = () => {
    const idUser = localStorage.getItem("userData");
    Services.getOne(idUser, "users")
      .then((res) => {
        const documents = res?.data?.data?.documents || [];
        const updatedDocs = {
          justificatif: { recto: null, verso: null },
          permis: { recto: null, verso: null },
          carteGrise: { recto: null, verso: null },
          assurance: { recto: null, verso: null },
        };
          console.log(documents)
        // Map documents to the corresponding doc types and sides
        documents.forEach((doc) => {
          switch (doc.name) {
            case "piece_identite":
              if (doc.card_side === "recto") {
                updatedDocs.justificatif.recto = doc;
              } else if (doc.card_side === "verso") {
                updatedDocs.justificatif.verso = doc;
              }
              break;
            case "permis_de_conduire":
              if (doc.card_side === "recto") {
                updatedDocs.permis.recto = doc;
              } else if (doc.card_side === "verso") {
                updatedDocs.permis.verso = doc;
              }
              break;
            case "carte_grise":
              if (doc.card_side === "recto") {
                updatedDocs.carteGrise.recto = doc;
              } else if (doc.card_side === "verso") {
                updatedDocs.carteGrise.verso = doc;
              }
              break;
            case "attestation_assurance":
              if (doc.card_side === "recto") {
                updatedDocs.assurance.recto = doc;
              } else if (doc.card_side === "verso") {
                updatedDocs.assurance.verso = doc;
              }
              break;
            default:
              break;
          }
        });
        

        setDocs(updatedDocs);
      })
      .catch((err) => {
        console.error("Error fetching documents:", err);
      });
  };

  useEffect(() => {
    getDocs();
  }, []);

  // Handle form submission
  const handleSubmit = () => {
    const formData = new FormData();

    // Append Recto and Verso files for each document type
    if (docs.justificatif.recto && !docs.justificatif.recto.updated_at) {
      formData.append("justificatif_recto", docs.justificatif.recto);
      formData.append("type", type)
    }
    if (docs.justificatif.verso) {
      formData.append("justificatif_verso", docs.justificatif.verso);
      formData.append("type", type)
    }

    if (docs.permis.recto) {
      formData.append("permis_recto", docs.permis.recto);
    }
    if (docs.permis.verso) {
      formData.append("permis_verso", docs.permis.verso);
    }

    if (docs.carteGrise.recto) {
      formData.append("carte_grise_recto", docs.carteGrise.recto);
    }
    if (docs.carteGrise.verso) {
      formData.append("carte_grise_verso", docs.carteGrise.verso);
    }

    if (docs.assurance.recto) {
      formData.append("assurance_recto", docs.assurance.recto);
    }
    if (docs.assurance.verso) {
      formData.append("assurance_verso", docs.assurance.verso);
    }

    Services.create(formData, "update_user")
      .then((res) => {
        toast.success('Votre opération a réussi !', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          onClose: ()=>getDocs(),
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast'
  
        });
        getDocs();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          className: "custom-toast-error",
        });
      });
  };

  const showDoc = (url) => {
    setUrl(url);
    setOpen(!open);
  };

  const onDelete = (type) => {
    Services.create({ name: type }, "documents")
      .then((res) => {
        getDocs();
      })
      .catch((err) => {
        console.error("Error deleting document:", err);
      });
  };

  const openTypeModal = (docType) => {
    console.log(docType)
    if (docType === "justificatif") {
      setOpenType(true);
    } else {
      document.getElementById(`${docType}RectoInput`).click();
    }
  };

  useEffect(() => {
    if (triggerFileInput) {
      document.getElementById("justificatifRectoInput").click();
      setTriggerFileInput(false);
    }
  }, [triggerFileInput]);

  const handleModalConfirm = () => {
    setOpenType(false);
    setTriggerFileInput(true);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Page mes documents </title>
          <meta
            name="description"
            content="mes documents (Cin, permis, assurance , ...)"
          />
        </Helmet>
      </HelmetProvider>

      {open && url && <ShowDocModal open={open} setOpen={setOpen} url={url} />}
      {openType && (
        <ModalType
          open={openType}
          setOpen={setOpenType}
          type={type}
          setType={setType}
          onConfirm={handleModalConfirm}
        />
      )}
      <div className="desktop">
        <Header connected={true} />
      </div>

      <div className="step6">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex align-items-center mb-100">
            <button onClick={() => navigate(-1)} className="cta">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </button>
            Devenir conducteur solidaire
          </h1>
        </div>
      </div>
      <div className="col-lg-6 mx-auto">
        <div className="px-16 mt-16 container-fluid-lg">
          <h2 className="bleuM500">Ajoutez vos documents</h2>
          <p className="current-text bleuM500 text-start">
            Si vous ajoutez vos documents, vous aurez plus de chances de trouver
            des passagers.
          </p>

          {/* Justificatif d'identité */}
          <DocumentCard
            docTitle="Justificatif d’identité"
            docType="justificatif"
            deleteType="piece_identite"
            docs={docs.justificatif}
            handleImageChange={handleImageChange}
            onDelete={onDelete}
            showDoc={showDoc}
            openTypeModal={openTypeModal}
          />

          {/* Permis de conduire */}
          <DocumentCard
            docTitle="Permis de conduire"
            docType="permis"
            deleteType="permis_de_conduire"
            docs={docs.permis}
            handleImageChange={handleImageChange}
            onDelete={onDelete}
            showDoc={showDoc}
            openTypeModal={openTypeModal}
          />

          {/* Carte Grise */}
          <DocumentCard
            docTitle="Carte grise"
            docType="carteGrise"
            deleteType="carte_grise"
            docs={docs.carteGrise}
            handleImageChange={handleImageChange}
            onDelete={onDelete}
            showDoc={showDoc}
            openTypeModal={openTypeModal}
          />

          {/* Attestation d’assurance */}
          <DocumentCard
            docTitle="Attestation d’assurance"
            docType="assurance"
            deleteType="attestation_assurance"
            docs={docs.assurance}
            handleImageChange={handleImageChange}
            onDelete={onDelete}
            showDoc={showDoc}
            openTypeModal={openTypeModal}
            className="mb-80"
          />
        </div>
        <div className="bottom-white-block">
          <button
            className="btn-primary-s p-16 w-sm-100"
            onClick={handleSubmit}
            // disabled={isSubmitDisabled}
          >
            Enregistrer
          </button>
        </div>
      </div>
    </>
  );
};

// Reusable DocumentCard component
const DocumentCard = ({
  docTitle,
  docType,
  docs,
  handleImageChange,
  onDelete,
  deleteType,
  showDoc,
  className,
  openTypeModal
}) => {
  return (
    <div className={`card-atch mt-8 ${className}`}>
      {/* Header with Title and Delete Button */}
      <div className="d-flex align-items-center justify-content-between mb-8">
        <div className="col">
          <p className="exergue text-center m-0 bleuM500">{docTitle}</p>
        </div>
        {(docs?.recto?.updated_at || docs?.verso?.updated_at) && (
          <button className="cta" onClick={() => onDelete(deleteType)}>
            <img src={Delete} alt="delete icon" />
          </button>
        )}
      </div>

      {/* Recto Section */}
      <div className="recto-section">
        {docs?.recto ? (
          <div>
            <>
                  { docs.recto?.updated_at &&<span className="date-file">
                    <img src={Check} className="mr-8" alt="check icon" />
                    Ajouté le{" "}
                    {new Date(
                      docs.recto?.updated_at
                    ).toLocaleDateString("fr-FR")}
                  </span>
                  }
                </>
            <p className="legend text-center mb-0 mt-8">
              Recto: {docs?.recto?.name}
             {docs?.recto?.updated_at && 
             <button
                className="cta"
                onClick={() => showDoc(docs?.recto?.full_uri)}
              >
                <img src={EyeOpened} alt="show file icon" className="ml-8" />
              </button>}
            </p>
          </div>
        ) : (
          <>
            <button
              className="btn-secondary-s p-14 w-100"
             // onClick={() => document.getElementById(`${docType}RectoInput`).click()}
             onClick={() => openTypeModal(docType)}
            >
              Ajouter Recto
            </button>
            <input
              type="file"
              id={`${docType}RectoInput`}
              accept="image/*,application/pdf"
              onChange={(e) => handleImageChange(e, docType, "recto")}
              style={{ display: "none" }}
            />
          </>
        )}
      </div>

      {/* Verso Section */}
      <div className="verso-section mt-8">
        {docs?.verso ? (
          <div className="text-center">
             <>
                  {/* { docs.verso?.updated_at &&<span className="date-file">
                    <img src={Check} className="mr-8" alt="check icon" />
                    Ajouté le{" "}
                    {new Date(
                      docs.verso?.updated_at
                    ).toLocaleDateString("fr-FR")}
                  </span>
                  } */}
                </>
            <p className="legend text-center mb-0 mt-8">
              Verso: {docs?.verso?.name}
              {docs?.verso?.updated_at && 
              <button
                className="cta"
                onClick={() => showDoc(docs?.verso?.full_uri)}
              >
                <img src={EyeOpened} alt="show file icon" className="ml-8" />
              </button>
              }
            </p>
          </div>
        ) : (
          <>
            <button
              className="btn-secondary-s p-14 w-100"
              onClick={() => document.getElementById(`${docType}VersoInput`).click()}
            >
              Ajouter Verso
            </button>
            <input
              type="file"
              id={`${docType}VersoInput`}
              accept="image/*,application/pdf"
              onChange={(e) => handleImageChange(e, docType, "verso")}
              style={{ display: "none" }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Docs;