import { useLocation, useNavigate } from "react-router-dom";
import ReturnIcon from "../../assets/images/return.svg";
import { useState, useEffect } from "react";
import Services from "../../services/global.service";
import CibleModal from "../../components/cibles";
import { toast } from "react-toastify";
import { HelmetProvider ,Helmet} from "react-helmet-async";

const Cible = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const savedCible = location?.state;
  // console.log({ savedCible });

  // Separate states for friends and groups
  const [cible, setCible] = useState(savedCible ? savedCible?.cible : "all");
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state
  const [selectedFriends, setSelectedFriends] = useState(
    savedCible?.cible === "friend"
      ? [{ id: savedCible?.friend?.ami?.id, name: savedCible?.friend?.ami?.nom }]
      : []
  );
  const [selectedGroups, setSelectedGroups] = useState(
    savedCible?.cible === "group"
      ? [{ id: savedCible?.group?.id, name: savedCible?.group?.groupe_name }]
      : []
  );
  const [open, setOpen] = useState(false); // Modal state
  const [data, setData] = useState([]); // Fetched data for modal

  // Update disabled button logic based on cible type
  useEffect(() => {
    const isDisabled =
      cible === "all"
        ? false
        : (cible === "friend" && selectedFriends.length === 0) ||
          (cible === "group" && selectedGroups.length === 0);

    setDisabledBtn(isDisabled);

    if (cible === "friend" || cible === "group") {
      const endPoint = cible === "friend" ? "amis" : "mygroup";
      Services.getAll(endPoint)
        .then((res) => {
          const fetchedData = Array.isArray(res?.data) ? res?.data : [];
          setData(fetchedData);
        })
        .catch((err) => {
          console.error("Error fetching data:", err);
        });
    }
  }, [cible, selectedFriends, selectedGroups]);

  const saveTarget = () => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    const existingTrajetData =
      JSON.parse(localStorage.getItem("detailsTrajet")) || {};

    const data = {
      horaires: existingTrajetData.transformedWeekDays,
      adresseDepart: existingTrajetData.depart,
      communes: JSON.stringify(existingTrajetData?.commune),
      codePostalArrivee: existingTrajetData.postalCode?.dest,
      codePostalDepart: existingTrajetData.postalCode?.dept,
      adrDepartLongitude: existingTrajetData?.departLatLng?.lng,
      adrDepartAltitude: existingTrajetData?.departLatLng?.lat,
      adresseFinal: existingTrajetData?.destination,
      adrFinalLongitude: existingTrajetData?.destinationLatLng?.lng,
      adrFinalAltitude: existingTrajetData?.destinationLatLng?.lat,
      nbrPlace: parseInt(existingTrajetData?.seats || 0, 10),
      Trajetregulier: existingTrajetData.regulier,
      trajetGratuit: existingTrajetData?.gratuit,
      prixTrajet: existingTrajetData?.gratuit
        ? 0
        : parseFloat(existingTrajetData?.formatedPrice || 0),
      commentaire: existingTrajetData?.comments,
      cibleTrajet: cible, // all , group, friend
      typeTrajet: 1,
    };

    if (existingTrajetData?.confirmationDetails?.depart?.date) {
      data.dateDepart = new Date(
        existingTrajetData?.confirmationDetails?.depart?.date
      );
    }

    if (cible === "friend") {
      data.amis = selectedFriends.map((friend) => friend.id);;
    } else if (cible === "group") {
      data.Idgroup = selectedGroups[0]?.id;
    }

     console.log(data);
     
    // Uncomment when ready for actual submission
    Services.create(data, "addTrajet")
      .then((res) => {
        localStorage.removeItem("detailsTrajet");
        localStorage.removeItem("weekDays");
        navigate("/confirmation-trajet");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          className: 'custom-toast-error',
        });
        setIsSubmitting(false); 

      });
  };

  return (
    <div className="bg-grey">
      <HelmetProvider>
        <Helmet>
            <title>Page Cible </title>
            <meta name="description" content="page cible création trajet" />
        </Helmet>
      </HelmetProvider>
      
      <CibleModal
        open={open}
        setOpen={setOpen}
        title={cible === "friend" ? "Mes ami(e)s" : "Mes groupes"}
        type={cible}
        data={data}
        selectedItems={cible === "friend" ? selectedFriends : selectedGroups}
        setSelectedItems={
          cible === "friend" ? setSelectedFriends : setSelectedGroups
        }
      />

      <div className="stepCible">
        <div className="container-fluid-lg p-16">
          <h1 className="text-white d-flex">
            <button onClick={() => navigate(-1)} className="cta">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </button>
            Votre trajet
          </h1>
        </div>
      </div>

      <h2 className="p-16 m-0 bleuM500 col-lg-6 mx-auto">
        Pour qui voulez-vous publier votre trajet ?
      </h2>

      <div className="px-16 mt-24 col-lg-6 mx-auto">
        <div className="project-options">
          {/* Option: Pour tous */}
          <div className="option-item">
            <div className="col">
              <label
                htmlFor="all"
                className="d-flex align-items-center justify-content-between current-text bleuM500"
              >
                <span className="exergue bleuM500">Pour tous</span>
                <input
                  type="radio"
                  name="projectAudience"
                  value="all"
                  id="all"
                  disabled={savedCible?.cible==="group" || savedCible?.cible==="friend" }
                  checked={cible === "all"}
                  onChange={() => {
                    setCible("all")
                    setSelectedFriends([])
                  }}
                />
                <span className="custom-radio"></span>
              </label>
            </div>
          </div>

          {/* Option: Mes ami(e)s */}
          <div
            className="option-item"
            onClick={() => {
              if (!savedCible || savedCible?.cible === "friend") {
                setSelectedGroups([]);
                setOpen(true);
                setCible("friend");
              }
            }}
          >
            <label className="text-start">
              <span className="exergue bleuM500">Mes ami(e)s</span>
              <p className="exergue bleu400">
                {cible === "friend" &&
                  selectedFriends.map((el, index) => (
                    <span key={el.id}>
                      {el.name}
                      {index !== selectedFriends.length - 1 && ", "}
                    </span>
                  ))}
              </p>
            </label>
          </div>

          {/* Option: Mes groupes */}
          <div
            className="option-item"
            onClick={() => {
              if (!savedCible || savedCible?.cible === "group") {
                setSelectedFriends([]);
                setOpen(true);
                setCible("group");
              }
            }}
          >
            <label className="text-start">
              <span className="exergue bleuM500">Mes groupes</span>
              <p className="exergue bleu400">
                {cible === "group" &&
                  selectedGroups.map((el, index) => (
                    <span key={el.id}>
                      {el.name}
                      {index !== selectedGroups.length - 1 && ", "}
                    </span>
                  ))}
              </p>
            </label>
          </div>
        </div>
      </div>

      <div className="bottom-white-block">
        <button
          type="submit"
          className="btn-primary-s p-16 w-sm-100"
          onClick={saveTarget}
          disabled={disabledBtn}
        >
          Publier mon trajet
        </button>
      </div>
    </div>
  );
};

export default Cible;

