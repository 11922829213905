import Tears from "../assets/images/tearsNumber.svg";

const BlocChiffres = () => {
  const data = [
    { chiffre: "+ de 650", text: "territoires ruraux utilisent la solution Atchoum" },
    { chiffre: "60%", text: "des demandes se font via le centre d’appel" },
    { chiffre: "80%", text: "des demandes de trajet sont validées" },
  ];

  return (
    <div className="container bloc-chiffre mt-24">
      <h2>Quelques chiffres</h2>
      <div className="row mx-0 mt-16">
        {data.map((el, index) => {
          const iconPositionClass = index % 2 !== 0 ? "left-icon" : "right-icon"; // Conditionally set class
          
          return (
            <div className="col-md-4 col-xs-12 pr-8" key={index}>
              <div className="card-chiffre">
                <p className="chiffre">
                  <span className={`position-relative icon-container `}>
                    <img src={Tears} alt="Icon" className={`floating-icon-chiffre ${iconPositionClass}`} loading="lazy"/>
                    {el?.chiffre}
                  </span>
                </p>
                <p className="text">{el?.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BlocChiffres;
