import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FormTrajet from "./formTrajet";
import Tears from "../assets/images/tears.svg"
import Covoiturage from "./covoiturage";
const BlocSearch = ({dataSearch}) => {
  return (
    <div className="bloc-search ">
        
       <h1 className="text-white mt-24">Besoin de vous déplacer ?</h1>
       <p className="subtitle text-white mt-8">Atchoum réalise vos souhaits</p>

      <div className="card-search mt-16 col-md-4 mx-auto col-xs-12 position-relative  icon-container">
        <img src={Tears} alt="Icon" className="floating-icon-home" loading="lazy"/> 
        <Tabs defaultActiveKey="trajet" id="tab-search" className="mb-3 custom-tabs" fill>
          <Tab eventKey="trajet" className="trajet" title="Trajet solidaire">
            <FormTrajet dataSearch={dataSearch}/>
          </Tab>
          <Tab eventKey="covoi" title="Covoiturage"  className=" covoi ">
            <Covoiturage/>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
export default BlocSearch;
