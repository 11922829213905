import Modal from 'react-bootstrap/Modal';
import IconClose from '../assets/images/iconClose.svg'

function ShowDocModal({open,setOpen,url}) {
  const isPDF = url?.endsWith('.pdf') || url?.includes('application/pdf'); // Check if the URL is a PDF
  return (
    <Modal show={open} onHide={()=>{setOpen(false)}}>
      <Modal.Header className="border-none d-flex justify-content-between">
          <Modal.Title className=" ">
          
            </Modal.Title>
            <button className="cta" onClick={()=>setOpen(false)}><img src={IconClose} alt="icon close"/></button>
         
        </Modal.Header>
   
      <Modal.Body>
      {isPDF ? (
          // Render an iframe for PDF display
          <iframe
            src={url}
            title="Document Viewer"
            width="100%"
            height="500px"
          ></iframe>
        ) : (
          // Render an image for non-PDF URLs
          <img src={url} alt="document image" className="w-100" />
        )}
      </Modal.Body>
  </Modal>
  );
}

export default ShowDocModal;