import { useState } from "react";
import NOImage from "../../assets/images/no-image.svg";
import Tears from "../../assets/images/tearsProfil.svg";
import Pen from "../../assets/images/pen.svg";
import ArrowRight from "../../assets/images/Arrow-right.svg";
import ModalAtchoum from "../../components/modal";
import { useNavigate } from "react-router-dom";
import Services from "../../services/global.service";

const MyAccount = () => {
  const [open, setOpen] = useState(false);
  const navigate=useNavigate()
  const openModal = (e) => {
    if(e==='delete'){
      setOpen(!open)
    }
    if(e==='disconnect'){
      localStorage.removeItem('token')
      localStorage.removeItem('userData')
      navigate('/')
    }
  };
  const deleteAccount=()=>{
    Services.updatePut(`users/${localStorage.getItem('userData')}/archive`)
    .then((res)=>{
      // console.log('res',res)
      localStorage.clear()
      navigate('/')
    })
    .catch((err)=>{

    })
  }
  return (
    <div className=" bg-grey">
      <div className="container pt-24 col-lg-6 mx-auto">
     {open && <ModalAtchoum open={open} deleteAccount={deleteAccount} setOpen={setOpen} 
     text="Êtes-vous sûr de vouloir supprimer votre compte ?"/>}
     <a href="/informations" className="no-decoration " >
     <div className="card-atch">
        <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
          Informations personnelles
          <img src={ArrowRight} alt="Arrow right" />
        </p>
      </div>
     </a>
     <a href="/changement-motdepasse" className="no-decoration" >
      <div className="card-atch mt-8">
        <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
        Modifier mon mot de passe
          <img src={ArrowRight} alt="Arrow right" className="" />
        </p>
      </div>
      </a>
      <a href="/compte-bancaires" className="no-decoration" >
      <div className="card-atch mt-8">
        <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
        Mes coordonnées bancaires
          <img src={ArrowRight} alt="Arrow right" className="" />
        </p>
      </div>
      </a>
      <a href="/paiements" className="no-decoration" >
      <div className="card-atch mt-8">
        <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
        Mes paiements
          <img src={ArrowRight} alt="Arrow right" className="" />
        </p>
      </div>
      </a>
      <a href="/notifs" className="no-decoration" >
      <div className="card-atch mt-8">
        <p className="exergue bleuM500 m-0 d-flex justify-content-between align-items-center">
        Notifications
          <img src={ArrowRight} alt="Arrow right" className="" />
        </p>
      </div>
      </a>
     <div className="mt-50 text-start "><button  className="cta text-underline" onClick={()=>{openModal('disconnect')}}>Se déconnecter</button> </div>
     <div className=" text-start mt-24"><button  className="cta text-underline" onClick={()=>{openModal('delete')}}>Supprimer mon compte</button> </div>
    </div>
    </div>
  );
};
export default MyAccount;
