import { Form, Formik, ErrorMessage } from "formik";
import Header from "../../components/header";
import ReturnIcon from "../../assets/images/return.svg";
import Alert from "../../assets/images/danger.svg";
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Range } from "react-range";
import Services from "../../services/global.service";
import { toast } from "react-toastify";
import { HelmetProvider ,Helmet} from "react-helmet-async";

const Deplacement=()=>{
  const navigate = useNavigate();
  const [adresse, setAdresse] = useState("");
  const [communes, setCommunes] = useState([]);
  const [selectedCommunes, setSelectedCommunes] = useState([]);
  const [selectedDistance, setSelectedDistance] = useState([20]);
  const [LatLng, setLatLng] = useState(null);
  const [error, setError] = useState("");
  const [isGMapsLoaded, setIsGMapsLoaded] = useState(false);
  // Load Google Maps script once on component mount
  useEffect(() => {
    if(window.google) setIsGMapsLoaded(true)
    if (!window.google) {
      // Only load if Google Maps is not already loaded
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      gmapScriptEl.async = true;
      gmapScriptEl.defer = true;
      gmapScriptEl.onload = () => setIsGMapsLoaded(true); // Set state when loaded
      document.body.appendChild(gmapScriptEl);
    } else {
      setIsGMapsLoaded(true); // Google Maps is already loaded
    }
  }, []);

  // Effect to fetch and filter communes based on selected distance and LatLng
  useEffect(() => {
    if (!LatLng) return; // Avoid fetching until LatLng is set

    fetch("https://geo.api.gouv.fr/communes?fields=nom,centre&format=json")
      .then((response) => response.json())
      .then((data) => {
        // console.log({data})
        const communes = data.map((commune) => ({
          name: commune.nom,
          lat: commune.centre.coordinates[1], // latitude
          lng: commune.centre.coordinates[0], // longitude
        }));
        const lo=communes.filter((commune) => {
          return commune.name=="Brioude"})
          // console.log({lo})
      
        const filteredCommunes = communes.filter((commune) => {
          const distance = calculateDistance(
            LatLng.lat,
            LatLng.lng,
            commune.lat,
            commune.lng
          );
          return distance <= selectedDistance;
        });
        const uniqueCommunes = filteredCommunes.filter(
          (commune, index, self) =>
            index === self.findIndex((c) => c.name === commune.name)
        );

        // console.log(uniqueCommunes);
        setCommunes(uniqueCommunes); // Set the filtered unique communes
      })
      .catch((error) => console.error("Error fetching commune data:", error));
  }, [LatLng, selectedDistance]);
  const clickCommune = (commune) => {
    // console.log(selectedCommunes.includes('Vinantes'))
    if (commune === "toute") {
      // console.log("Selecting all communes");
      // Check if communes are available before setting them
      if (communes.length > 0) {
        setSelectedCommunes(
          communes.map((e) => {
            return e.name;
          })
        );
      } else {
        // console.error("No communes available to select.");
      }
      // console.log("setSelectedCommunes", selectedCommunes);
    } else {
     
      if (selectedCommunes.includes(commune)) {
        // console.log('selectedCommunes.includes(commune)',commune)
        // Commune already selected, remove it
        setSelectedCommunes(
          selectedCommunes.filter(
            (selectedCommune) => selectedCommune !== commune
          )
        );
      } else {
        // console.log('else',commune)
        // Commune not selected, add it
        setSelectedCommunes([...selectedCommunes, commune]);
        // console.log('selectedcommu,e',selectedCommunes)
      }
    }
  };
  // Distance calculation function
  function calculateDistance(lat1, lon1, lat2, lon2) {
    // console.log(lat1, lon1, lat2, lon2)
    const R = 6371; // Radius of the Earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      0.5 -
      Math.cos(dLat) / 2 +
      (Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        (1 - Math.cos(dLon))) /
        2;

    return R * 2 * Math.asin(Math.sqrt(a));
  }
 
  // Handle form submission
  const handleSubmit = () => {
    // console.log("Form data:", communes);
  //  setSubmitting(false);
  const data={
    communeKM:selectedDistance[0],
    communes:selectedCommunes,
    adresse_domicile:adresse,
    domicileLat:LatLng.lat,
    domicileLng:LatLng.lng


  }
  // console.log(data)
  Services.create(data,'update_user')
  .then((res)=>{
    // console.log(res)
  })
  .catch((err)=>{
    // console.log(err)
    toast.error(err?.response?.data?.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
     // theme: 'light', // Choose theme (light/dark)
      className: 'custom-toast-error'

    });
  })
  // navigate('/docs-solidaire')
  };
  const getMyProfil = () => {
    const idUser = localStorage.getItem('userData');
    // console.log('userData', idUser);
    Services.getOne(idUser, 'users')
      .then((res) => {
        const userData = res?.data?.data;
  
        userData?.adresse_domicile && setAdresse(userData?.adresse_domicile)  
        userData?.communes && setSelectedCommunes(userData?.communes?.map(commune => commune.name))    
        userData?.communeKM && setSelectedDistance([userData?.communeKM])  
        userData?.domicileLat && setLatLng({lat:userData?.domicileLat,lng:userData?.domicileLng})
        // console.log(userData);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  
  useEffect(()=>{getMyProfil()},[])
  // Handle address change and selection
  const handleChangeAddress = (address, setFieldValue) => {
    // console.log('change',address.length)
    setAdresse(address);
    setError("");
    setLatLng(null);
  };

  const handleSelect = async (address, setFieldValue) => {
    setAdresse(address);
    setSelectedCommunes([])
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLatLng(latLng);
      setError("");
      const addressComponents = results[0].address_components;

      // Find the component with 'locality' type for city/commune
      const cityComponent = addressComponents.find(component => 
       component.types.includes('locality')
     );

     // Set the commune (city)
      // console.log({cityComponent})
    } catch (error) {
      // console.error("Error", error);
    }
  };

  // Autocomplete search options
  const searchOptions = {
    componentRestrictions: { country: ["fr"] },
  types: ["geocode","establishment"],
  };
      return (
        <>
        <HelmetProvider>
          <Helmet>
            <title>Page périmetre de déplacement </title>
            <meta name="description" content="page pour renseigner les communes de déplacement " />
        </Helmet>
        </HelmetProvider>
             
          <div className="desktop">
            <Header connected={true} />
          </div>
          <div className="bg-grey">
          <div className="bg-atchoum-profil">
            <div className="container-fluid-lg">
              <h1 className="text-white p-16 d-flex">
                <button onClick={() => navigate(-1)} className="cta">
                  <img src={ReturnIcon} className="mr-8" alt="return icon" />
                </button>
                Devenir conducteur solidaire
              </h1>
            </div>
            </div>
            <div className="p-16 col-lg-6 mx-auto">
    
                  <h2 className='bleuM500'>Définissez votre périmètre de déplacement</h2>
    
              <p className="current-text">
                Indiquez les communes dans lesquelles vous acceptez de vous
                déplacer. Les passagers verront votre profil si leurs trajets
                concernent votre périmètre.
              </p>
    
              <Formik initialValues={{ adresse: "" }} onSubmit={handleSubmit}>
                {({ setFieldValue }) => (
                  <Form className="mt-24 ">
                    <div className="">
                      <p className="exergue">
                        <span className="number-round mr-8">1</span>Votre adresse de
                        domicile
                      </p>
                      {/* Adresse Field with Autocomplete */}
                      <div className="floating-input-container mt-8">
                        {isGMapsLoaded && (
                          <Autocomplete
                            value={adresse}
                            searchOptions={searchOptions}
                            onChange={(address) =>
                              handleChangeAddress(address, setFieldValue)
                            }
                            onSelect={(address) =>
                              handleSelect(address, setFieldValue)
                            }
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div className="floating-input-container ">
                                <input
                                  {...getInputProps({
                                    placeholder: "",
                                    className: `floating-input ${
                                      error ? "danger-input" : ""
                                    }`,
                                  })}
                                  id="floatingAdr"
                                  name="adresse"
                                />
                                <label
                                  htmlFor="floatingAdr"
                                  className="floating-label"
                                >
                                  Adresse
                                </label>
                                <div
                                  className={
                                    suggestions.length > 0
                                      ? "container-autocomplete"
                                      : ""
                                  }
                                >
                                  {loading ? <div>Loading...</div> : null}
                                  {suggestions.map((suggestion) => {
                                    const style = {
                                      backgroundColor: suggestion.active
                                        ? "#41B6E6"
                                        : "#fff",
                                    };
                                    return (
                                      <div
                                        className="mapItem"
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </Autocomplete>
                        )}
                        <ErrorMessage name="adresse">
                          {(msg) => (
                            <div className="error-message">
                              <img src={Alert} alt="Alert" />
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className=" mt-24 mx-auto">
                      <p className="exergue">
                        <span className="number-round mr-8">2</span>Sélectionnez les
                        communes
                      </p>
                      <p className="current-text bleu400">
                        Vous recevrez des demandes de trajet solidaires depuis ces
                        communes.
                      </p>
                      {/* Range Slider for selecting distance */}
                      <div className="mt-32 card-communes">
                        <Range
                          step={1}
                          min={0}
                          max={100}
                          values={selectedDistance}
                          onChange={(values) => setSelectedDistance(values)}
                          renderTrack={({ props, children }) => {
                            const trackStyle = {
                              height: "9px",
                              width: "100%",
                              backgroundColor: "#CEE6E1", // Grey for the right side
                              borderRadius: "24px",
                              position: "relative",
                            };
    
                            const leftTrackStyle = {
                              height: "9px",
                              width: `${(selectedDistance[0] / 100) * 100}%`, // Adjust width based on distance
                              backgroundColor: "#0C836B", // Green for the left side
                              borderRadius: "24px",
                              position: "absolute",
                              left: 0,
                            };
    
                            return (
                              <div {...props} style={trackStyle}>
                                <div style={leftTrackStyle} />
                                {children}
                                <span
                                  className="bubble"
                                  style={{
                                    position: "absolute",
                                    top: "-58px", // Adjust to control the distance above the thumb
                                    left: `calc(${
                                      (selectedDistance / 100) * 100
                                    }% - 30px)`, // Center the bubble above the thumb
                                  }}
                                >
                                  {selectedDistance[0]} km
                                </span>
                              </div>
                            );
                          }}
                          renderThumb={({ props }) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: "24px",
                                width: "24px",
                                borderRadius: "50%",
                                borderRadius: "50%",
                                backgroundColor: "#FFF", // White background for the thumb
                                borderTop: "6px solid #0C836B", // Custom top border (green)
                                borderBottom: "6px solid #0C836B", // Custom bottom border (green)
                                borderLeft: "6px solid #0C836B", // Remove left and right borders if not needed
                                borderRight: "6px solid #0C836B",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // backgroundColor: "#fff",
                                // boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
                              }}
                            />
                          )}
                        />
    
                        <div className="row  mt-16 ">
                          {(communes && communes.length !== 0 ) && adresse.length!==0 ? (
                            <>
                              {/* "Toutes les communes" Option */}
                              <div
                                className={`col-auto mr-8 mb-8 commune current-text bleuM500 ${
                                  selectedCommunes.length === communes.length &&
                                  communes.length !== 0
                                    ? "selected-commune"
                                    : ""
                                }`}
                                onClick={() => {
                                  clickCommune("toute");
                                }}
                              >
                                Toutes les communes
                              </div>
    
                              {/* Individual Commune Options */}
                              {communes.map((commune) => (
                                <div
                                  key={commune.name}
                                  className={`col-auto mr-8 mb-8 commune current-text bleuM500 ${
                                    selectedCommunes.includes(commune.name)
                                      ? "selected-commune"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    clickCommune(commune.name);
                                  }}
                                >
                                  {commune.name}
                                </div>
                              ))}
                            </>
                          ):
                          <p className="error-message text-start d-flex">
                             <img src={Alert} alt="Alert" /> Veuillez entrer votre adresse domicile pour selectionnez les communes
                          </p>
                          }
                        </div>
                      </div>
                    </div>
                        <div className="h-xs-100"></div>
                    <div className="bottom-white-block">
                      <button type="submit" className="btn-primary-s p-16 w-sm-100"
                      onClick={handleSubmit}
                      disabled={adresse.length===0}
                      >
                        Enregistrer
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </>
      );
}
export default Deplacement;