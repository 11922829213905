import { useNavigate, useParams } from "react-router-dom";
import ReturnIcon from "../../assets/images/return.svg";
import Message from "../../assets/images/MessageBgT.svg";
import More from "../../assets/images/more-2-fill.svg";
import AddFriends from "../../assets/images/Addfriends.svg";
import TrashIcon from "../../assets/images/trashbtn.svg";

import { useEffect, useState } from "react";
import Services from "../../services/global.service";
import "../../assets/styles/amis.scss";
import Tabs from "../../components/tabs";
import TrajetGroup from "../../components/trajetGroup";
import Actions from "../../components/modalActions";
import Quitter from "../../components/quitterGroupe";
import ModalAtchoum from "../../components/modal";
// import {  } from "react-helmet";
import { HelmetProvider ,Helmet} from "react-helmet-async";
const GroupProfil = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [group, setGroup] = useState();
  const [actionsModal, setActionsModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [action, setAction] = useState("quitter");
  const [members, setMembers] = useState([]);
  const [trajets, setTrajets] = useState([]);
  const getGroupe = () => {
    Services.getOne(params.id, "groupes/details-groupe")
      .then((res) => {
        // console.log(res);
        setGroup(res?.data);
        setMembers(res?.data?.members);
        setTrajets(res?.data?.groupe_trajet);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const removeMember = () => {
    const data = {
      idGroupe: group?.id,
      idMember: memberToDelete,
    };
    Services.create(data, "groupes/delete-member-groupe")
      .then((res) => {
        // console.log(res);
        getGroupe();
        setOpenDelete(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const Members = () => {
    return (
      <div className="p-16">
        <ModalAtchoum
          open={openDelete}
          setOpen={setOpenDelete}
          text={"Voulez vous vraiment supprimer ce membre !!"}
          deleteAccount={removeMember}
        />
        {members?.length > 0
          ? members.map((el, index) => (
              <div
                //onClick={() => navigate(`/amis/${el?.group_user?.id}`)}
                className="cta no-decoration w-100"
                key={index}
              >
                <div className="d-flex containerFriend p-8 mt-8 align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="driver-icon  mr-8">
                      <img
                        src={
                          el?.group_user?.photo_profil?.length > 0
                            ? el?.group_user?.photo_profil
                            : ""
                        }
                        alt="ami avatar"
                        className="driver-icon mr-8"
                        style={{
                          width: " 100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    <p className="exergue bleuM500">
                      {el?.group_user?.nom} {el?.group_user?.prenom}
                    </p>
                  </div>
                  <div>
                    {/* Status handling */}
                    {group?.managed_by == localStorage.getItem("userData") &&
                      el?.group_user?.id !== group?.managed_by && (
                        <button
                          onClick={() => {
                            setMemberToDelete(el?.group_user?.id);
                            setOpenDelete(!openDelete);
                          }}
                          className="cta"
                        >
                          <img src={TrashIcon} alt="delete carnet" />
                        </button>
                      )}
                  </div>
                </div>
              </div>
            ))
          : "Aucun membre "}
      </div>
    );
  };
  const OurTarget = () => {
    // Group trajets by etat
    const groupedTrajets = trajets.reduce((acc, item) => {
      const { trajet } = item;
      if (!acc[trajet?.status]) {
        acc[trajet?.status] = [];
      }
      acc[trajet?.status].push(item);
      return acc;
    }, {});

    return (
      <div className="p-16">
        {groupedTrajets && Object.keys(groupedTrajets)?.length > 0 ? (
          Object.keys(groupedTrajets).map((etatKey) => (
            <div key={etatKey}>
              {/* Display the group title */}
              <h2 className="bleuM500 mt-8 mb-0 pb-16">
                {etatKey == 1
                  ? "Trajets en attente"
                  : etatKey == 2
                  ? "Trajets à venir"
                  : "Trajets passés"}
              </h2>
              {/* Render trajets within this group */}
              {groupedTrajets[etatKey].map((el, index) => (
                <TrajetGroup
                  key={index} // Use a unique key for each item
                  trajet={el?.trajet}
                  from="group"
                  trajetType="aller"
                />
              ))}
            </div>
          ))
        ) : (
          <p className="current-text bleuM500">Aucun trajet disponible.</p>
        )}
      </div>
    );
  };

  const checkdiscussion = (id) => {
    // console.log(`Checking discussion for id: ${id}`);
    Services.getOne(id, "messages/getOrCreateMyDiscussionsGroupe")
      .then((res) => {
        // console.log(res?.data?.id);
        navigate("/discussion/" + res?.data?.id);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const onAction = () => {
    // console.log(action);
    if (
      action === "quitter" &&
      group?.managed_by == localStorage.getItem("userData")
    ) {
      setActionsModal(false);
      setOpen(true);
    } else {
      Services.create({}, `groupes/quitter-groupe/${group?.id}`)
        .then((res) => {
          // console.log(res);
          setActionsModal(false); // Close modal after successful submission
          navigate("/amis-groupes");
        })
        .catch((err) => {
          // console.log(err);
        });
    }
    if (
      action === "delete" &&
      group?.created_by == localStorage.getItem("userData")
    ) {
    }
  };
  useEffect(() => {
    getGroupe();
  }, []);
  const tabs = [
    { key: "membres", label: "Les membres", content: <Members /> },
    { key: "trajets", label: "Nos Trajets", content: <OurTarget /> },
  ];
  return (
    <div className="container-fluid-lg  bg-grey">
      <HelmetProvider>
         <Helmet>
        <title>Page details groupe </title>
        <meta name="description" content="details groupe" />
      </Helmet>
      </HelmetProvider>
     
      <Actions
        created_by={group?.managed_by}
        onAction={onAction}
        open={actionsModal}
        setOpen={setActionsModal}
        action={action}
        setAction={setAction}
      />
      <Quitter
        open={open}
        setOpen={setOpen}
        members={members}
        group_id={group?.id}
      />
      <div className="col-lg-6 mx-auto ">
        <div className="d-flex justify-content-between p-16 align-items-start">
          <button onClick={() => navigate(-1)} className="cta">
            <img src={ReturnIcon} className="mr-8" alt="return icon" />
          </button>
        </div>
        <div className="px-16">
          <div className="card-atchoum mr-0 bg-white public-profil  ">
            <div
              className="text-end"
              onClick={() => {
                setActionsModal(!actionsModal);
              }}
            >
              <img src={More} alt="Check" className="mr-4" />
            </div>
            <h2 className="text-center bleuM500  mt-8">{group?.groupe_name}</h2>
            <p className="legend bleu400 mt-8 text-center">
              Groupe - {group?.members?.length} membres
            </p>
            <p className="current-text bleuM500 mt-8">{group?.description}</p>
            <div className="mt-16">
              <div className="d-flex justify-content-between">
                <button
                  className="btn-secondary-s w-100  mr-8"
                  onClick={() => navigate(`/group/invit/${group?.id}`)}
                >
                  <img src={AddFriends} alt="Check" className="mr-4" />
                  Inviter
                </button>
                <button
                  className="btn-secondary-s  w-100 "
                  onClick={() => {
                    checkdiscussion(group?.id);
                  }}
                >
                  <img src={Message} alt="message" className="mr-4" />
                  Ecrire un message
                </button>
              </div>
              <button
                className="btn-primary-s w-100 p-14  mt-16"
                onClick={() => {
                  navigate("/details-covoiturage", {
                    state: { cible: "group", group },
                  });
                }}
              >
                Proposer un trajet à mon groupe
              </button>
            </div>
          </div>
        </div>

        <div className="mt-24">
          <Tabs tabs={tabs} className="bg-white" />
          <div className="h-xs-100"></div>
        </div>
      </div>
    </div>
  );
};
export default GroupProfil;
