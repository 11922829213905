import React, { useState } from "react";
import { Card, CardBody, CardImg, Modal, Button } from "react-bootstrap";
import PlayIcon from "../assets/images/controls-play.svg";

const ItemBlog = ({ data }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = (youtubeUrl) => () => {
    if (youtubeUrl) {
      setShowModal(true);
    }
  };

  const closeModal = () => setShowModal(false);

  return (
    <>
      <Card className="card-atchoum p-8" onClick={openModal(data?.youtube_url)}>
        <div className="w-100">
          <CardImg
            src={data?.image_video}
            className="cover-image"
            alt="item img"
          />
        </div>

        <CardBody className="p-0 text-start">
          <p className="textBlog bleuM500 mt-16 min-h-56">{data?.title}</p>
          <span className="timing mt-16">
            <img
              src={PlayIcon}
              alt="icon play"
              loading="lazy"
              className="mr-8"
            />
            {data?.video_duration}
          </span>
        </CardBody>
      </Card>

      {/* Video Modal */}
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{data?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data?.youtube_url ? (
            <iframe
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${getYouTubeID(data.youtube_url)}`}
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <p>No video available</p>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

// Utility function to extract YouTube ID from URL
const getYouTubeID = (url) => {
  const regExp = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/)?([a-zA-Z0-9_-]{11})|youtu\.be\/([a-zA-Z0-9_-]{11})/;
  const match = url.match(regExp);
  return match ? match[1] || match[2] : null;
};

export default ItemBlog;
