import { Tab, Tabs } from "react-bootstrap"
import TopBar from "../../components/topBar"
import MyAccount from "./account"
import './profil.scss'
import Profil from "./profil"
import { useEffect, useState } from "react"
import Services from "../../services/global.service"
import Header from "../../components/header"
import { HelmetProvider ,Helmet} from "react-helmet-async"

const MyPorfile=()=>{
  const [user,setUser]=useState()
  const getMyProfil=()=>{
    const idUser=localStorage.getItem('userData')
    // console.log('userData',idUser)
    Services.getOne(idUser,'users')
    .then((res)=>{
      // console.log(res)
      setUser(res?.data?.data)
    })
    .catch((err)=>{
      // console.log(err)
    })
  }
  useEffect(()=>{getMyProfil()},[])
    return(
        <>
        <HelmetProvider>
          <Helmet>
              <title>Page mon compte </title>
              <meta name="description" content="mon compte et mon profil" />   
          </Helmet>
        </HelmetProvider>
        
          <Header connected={true}/>
        <Tabs defaultActiveKey="my-profil" id="tab-profil" className="mb-3" fill>
          <Tab eventKey="my-profil" className="profil" title="Mon profil">
          {user && <Profil user={user}/>} 
          </Tab>
          <Tab eventKey="my-account" title="Mon compte"  className="profil">
            <MyAccount/>
          </Tab>
        </Tabs>
        <TopBar/>
        </>
    )
}
export default MyPorfile