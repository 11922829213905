import { useEffect, useState } from "react"
import Services from "../services/global.service"

const BlocResultEmptySolidaire=(props)=>{
    const [tus,setTus]=useState(false)
    const getMyProfil=()=>{
      const idUser=localStorage.getItem('userData')
      // console.log('userData',idUser)
      if(idUser){
        Services.getOne(idUser,'users')
        .then((res)=>{
          console.log({res})
          setTus(res?.data?.data?.zonetus)
        })
        .catch((err)=>{
           console.log(err)
        })
      }
      else{
        Services.create({codeP:props?.depart},'trajet/verifier-zone-tus')
        .then((res)=>{
          console.log({res})
          setTus(res?.data?.success)
        })
        .catch((err)=>{
           console.log(err)
        })
      }
      
    }
    useEffect(()=>{getMyProfil()},[])
return(
    <>
  
        <div className="d-flex flex-column align-items-center">
            <div className="h-xs-100"></div>
            {props.title &&<p className={`subtitle text-center bleuM500 w-75`}>
            Aucun résultat pour votre trajet recherché ...
            </p>
}
         
         {tus ?   
             <><p className="current-text text-center bleuM500">
            Pour toute demande d’information.
            </p>
            <p className="exergue bleuM500 text-center">
            Contactez-nous via la hotline : <br/>
            0 806 110 444 
            </p>
            <p className="legend bleu400">(Service gratuit + prix appel local)</p>
            </>
           : <><p className="current-text text-center bleuM500">
           Pour toute demande d’information :
           </p>
           <p className="exergue bleuM500 text-center">
           
           contact@atchoum.eu
           </p>
          
           </> }
        </div>
      

    </>
)
}
export default BlocResultEmptySolidaire