import To from '../assets/images/to.svg'
const SearchDetails=({data})=>{
    const formatDate = (dateString) => {
        // console.log(dateString)
        const [datePart, timePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        
        const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
        const monthNames = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
    
        const dayName = daysOfWeek[date.getDay()];
        const monthName = monthNames[month - 1];
        const [hours, minutes] = timePart.split(':');
        
        const formattedDate = `${dayName?.slice(0, 3)}. ${day?.toString().padStart(2, '0')} ${monthName} à ${hours}h${minutes}`;
        return(formattedDate);
      };
    return(
        <>
        <div className="search-details mt-8">
        <div className="d-flex">
            <span className='limited-text bleuM500'>{data?.adresseDepart || "10 rue de l’église, 10400 Montpothier "} </span>  
           <img src={To}  alt="to icon" /> 
           <span className='limited-text bleuM500'>{data?.adresseDestination || "37 rue de l’égalité, 93200 Les Lilas "}   </span>
            </div>   
            <div className="d-flex justify-content-between">
            <p className='bleu400 mb-0 text-start legend'>{data?.dateDepart ? formatDate(data?.dateDepart) : "Flexible"}  </p> 
            {data?.dateArrive && 
            (
           <> <img src={To}  alt="to icon" className='rotate180' /> 
            <p className='bleu400 mb-0 text-start legend'>{data?.dateArrive ? formatDate(data?.dateArrive) : ""}  </p>
            </> 
            ) }
            </div>
   
                
         </div>
        </>
    )
}
export default SearchDetails