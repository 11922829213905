
import Modal from "react-bootstrap/Modal";
import Signal from "../../assets/images/signal.svg";
import Trash from "../../assets/images/trashbtn.svg";
import Services from "../../services/global.service";
import RaisonSignal from "../../components/modalRaison";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function DeleteMessage({setMessages,show,setShow,idMsg, style}) {
  // console.log({idMsg})
  const [deleting, setDeleting] = useState(false);
  const navigate=useNavigate()
  useEffect(()=>{},[deleting])
const deleteMsg=()=>{
  setDeleting(true);
  setMessages((prevMessages) =>
    prevMessages.map((msg) =>
      msg.id === idMsg ? { ...msg, content: "Message supprimé." } : msg
    )
  );
  Services?.create({},`messages/delete-message/${idMsg}`)
  .then((res)=>{
    // console.log(res)
    setShow(false)
   
  })
  .catch((err)=>{
    toast.error(err?.response?.data?.message, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
     // theme: 'light', // Choose theme (light/dark)
      className: 'custom-toast-error'

    });
    // console.log(err)
  })
}


  return (
    <>
      <Modal
        show={show}
       onHide={()=>setShow(false)}
    className="msg-modal"
        keyboard={false}
        // style={style}
      >
        <Modal.Body>
          <div className=" exergue ">
            {" "}
            <bouton  className="d-flex align-items-center justify-content-between"
             onClick={deleteMsg}>
            Supprimer le message <img src={Trash} alt="trash icon" />
            </bouton>{" "}
          </div>
     
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteMessage;
