import { Button } from "react-bootstrap";
import Logo from "../assets/images/logo.svg";
import "../assets/styles/header.scss";
import { useNavigate } from "react-router-dom";
import TopBar from "./topBar";
import { useMyContext } from "../context";
const Header = ({connected}) => {
    // console.log('connected',connected)
  const navigate = useNavigate(); // Create navigate function

  return (
    <>
    {!connected ?
      <header className="not-connected-header">
        <a href="/">
          <img src={Logo} alt="logo atchoum"  loading="lazy"/>
        </a>
        <div>
          <button
            className="btn-secondary-s mr-8"
            onClick={() => navigate("/login")} // Navigate to login page
          >
            Se connecter
          </button>
          <button
            className="btn-primary-s"
            onClick={() => navigate("/register")} // Navigate to register page
          >
            S'inscrire
          </button>
        </div>
      </header>
      :<header className="connected-header">
      <div className="col-auto">
        <a href="/">
        <img src={Logo} alt="logo atchoum" loading="lazy" />
      </a>
        </div>
      <div className="col mx-auto">
       <TopBar header={true}/>
      </div>
    </header>
    }
      
    </>
  );
};
export default Header;
