import Modal from 'react-bootstrap/Modal';
import IconClose from '../assets/images/iconClose.svg'

function InfoModal({open,setOpen,title,text}) {
  return (
    <Modal show={open} onHide={()=>{setOpen(false)}}>
      <Modal.Header className="border-none d-flex justify-content-between">
          <Modal.Title className=" ">
            <h1 className="bleu600">{title}</h1> 
            </Modal.Title>
            <button className="cta" onClick={()=>{setOpen(false)}}>
              <img src={IconClose} alt="icon close" loading="lazy"/></button>
         
        </Modal.Header>
   
    <Modal.Body>
      <p className="current-text">{text}</p>
    </Modal.Body>
  </Modal>
  );
}

export default InfoModal;